import React, { useState } from "react";
import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import FilesList from "./components/FilesList/FilesList";

function TrashFiles() {
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleDocumentSelection = (selected) => {
    setSelectedUsers(selected);
  };
  return (
    <DashboardLayout isRecycle={true}>
      <FilesList
        selectedUsers={selectedUsers}
        onDocumentSelect={handleDocumentSelection}
      />
    </DashboardLayout>
  );
}

export default TrashFiles;
