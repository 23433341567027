import React from "react";
import CardHeader from "@mui/material/CardHeader";
import { Card, CardContent, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import JumboBookmark from "@jumbo/components/JumboBookmark";
import { getAssetPath } from "./../../../../../utils/appHelpers";
import { ASSET_IMAGES } from "./../../../../../utils/constants/paths";
import Span from "./../../../../../../@jumbo/shared/Span/Span";

const Item = ({ children, sx }) => (
  <Div
    sx={{
      textAlign: "center",
      flexBasis: "33.33%",
      p: (theme) => theme.spacing(1, 2),
      ...sx,
    }}
  >
    {children}
  </Div>
);

const ActionButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  borderBottom: "none",
  borderRadius: 0,
  textTransform: "none",
  letterSpacing: 0,
  borderColor: theme.palette.divider,
  color: theme.palette.text.secondary,

  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderBottom: "none",
  },
}));

const FolderListItem = ({ user }) => {
  return (
    <Card sx={{ height: "250px" }}>
      <CardHeader
        avatar={
          <>
            <Span>
              <JumboBookmark
                value={user.isFavorite}
                sx={{ verticalAlign: "middle", fontSize: "small" }}
              />
              <MoreHorizIcon sx={{ verticalAlign: "middle" }} />
              <img
                src={getAssetPath(`${ASSET_IMAGES}/pin-bold.png`, "16x16")}
                alt="pin"
                width={18}
                style={{
                  verticalAlign: "middle",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
              <img
                src={getAssetPath(`${ASSET_IMAGES}/shareIcon.png`, "16x16")}
                alt="pin"
                width={16}
                style={{ verticalAlign: "middle" }}
              />
            </Span>
          </>
        }
        sx={{ pb: 0 }}
      />
      <CardContent
        sx={{
          pt: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Div sx={{ mb: 3 }}>
          <img
            src={getAssetPath(`${ASSET_IMAGES}/folderIcon.png`, "63x63")}
            alt="pin"
            width={63}
          />
        </Div>
        <Typography variant={"h5"} mb={0.75}>{`${user.fullName}`}</Typography>
        <Typography variant={"h6"} color="text.secondary" mb={0.5}>
          100 MB
          {/* {user.title} */}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default FolderListItem;
