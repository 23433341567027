import React, {useState,useEffect} from 'react';
import Stack from "@mui/material/Stack";
import {menuItems} from "./data";
import {Card,  Typography} from "@mui/material";
import JumboCheckbox from "@jumbo/components/JumboCheckbox";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import RoleForm from './RoleForm';


const Item = styled(Span)(({theme}) => ({
    padding: theme.spacing(0, 1),
}));

const RoleItem = ({user , handleUpdateRole,handleDeleteRole , refetchRole}) => {
    const {showDialog, hideDialog} = useJumboDialog();
    
    const showEditRoleDialog = (userData) => {
        showDialog({
            title: "Edit Role",
            content: <RoleForm user={userData} handleUpdateRole={handleUpdateRole} hideDialog={hideDialog} refetchRole={refetchRole}/>
          
        });
    };
   
    return (
        <Card sx={{mb: 1}}>
            
            <Stack direction={"row"} alignItems={"center"} sx={{p: theme => theme.spacing(2, 1)}}>
                <Item
                    sx={{
                        flex: {xs: 1, md: '0 1 30%', lg: '0 1 30%'}
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'}>
                        <Item sx={{ml: -1}}>
                            <JumboCheckbox value={user.isChecked} sx={{verticalAlign: 'middle'}}/>
                        </Item>
                      
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{`${user.name} `}</Typography>
                           
                        </Item>
                    </Stack>
                </Item>
                <Item
                    sx={{
                        alignSelf: 'flex-start',
                        flexBasis: {md: '35%', lg: '35%'},
                        display: {xs: 'none', md: 'block'}
                    }}
                >
                    <Typography variant={"h6"} mt={1} lineHeight={1.25}>{user.description}</Typography>
                </Item>
                <Item
                    sx={{
                        flexBasis: '30%',
                        display: {xs: 'none', lg: 'block'}
                    }}
                >
                  
                            <Typography variant={"h6"} mb={.5}>{user.createdAt}</Typography>
                            <Typography variant={"body1"} color="text.secondary">created date</Typography>
                       
                </Item>
                <Item
                    sx={{
                        ml: 'auto',
                        display: {xs: 'none', sm: 'block'}
                    }}
                >
                 
                </Item>
                <Item sx={{ml: {xs: 'auto', sm: 0}}}>
                <JumboDdMenu menuItems={menuItems} onClickCallback={action => {
                    if (action === "edit") {
                        showEditRoleDialog(user);;
                    } else if (action === "delete") {
                        handleDeleteRole(user.id);
                    }
                }} />
                  
                </Item>
            </Stack>
                    
        </Card>
    );
};

export default RoleItem;
