import { Card, CardContent, Typography, Button, MenuItem } from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import Div from "@jumbo/shared/Div";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAuthApiActions } from "../query/useAuthApiActions";
import OutlinedSecondaryTextField from "./../../../../../@jumbo/components/Textfield/index";
import { useNavigate } from "react-router-dom";
import AuthLayout from "app/layouts/auth-layout/AuthLayout";
import { useFetchIndustry } from "../query/useFetchIndustry";
import { useFetchIndustryDepartment } from "../query/useFetchIndustryDepartment";
import { useFetchEmpStrength } from "../query/useFetchEmpStrength";
import useQuestionForm from "./../hooks/useQuestionForm";

const defaultValues = {
  workspaceName: "",
  employeeStrengthId: "",
  industryId: "",
  industryDepartmentId: "",
  userId: "",
  code: "",
};

function QuestionAirForm() {
  const [indusID, setIndusId] = useState("");

  const { location } = window;
  const userId = location.pathname?.split("/")?.[3]
    ? location.pathname?.split("/")?.[3]
    : "";
  const code = location.pathname?.split("/")?.[4]
    ? location.pathname?.split("/")?.[4]
    : "";

  const navigate = useNavigate();

  const { tryWorkspace } = useAuthApiActions();
  const { data: industryData } = useFetchIndustry();

  const { data: departmentData, refetch } = useFetchIndustryDepartment(
    indusID,
    false
  );

  const { data: empStrengthData } = useFetchEmpStrength();

  useEffect(() => {
    if (indusID) {
      refetch();
    }
  }, [indusID]);

  const handleGoToQuestionAir = () => {
    navigate("/packages");
  };

  const industryList = useMemo(
    () =>
      industryData?.map((item) => {
        return { id: item.id, name: item.industryName };
      }) || [],
    [industryData]
  );

  const industryDeptList = useMemo(
    () =>
      departmentData?.map((item) => {
        return { id: item?.id, name: item?.departmentName };
      }) || [],
    [departmentData]
  );

  const EmpStrengthList = useMemo(
    () =>
      empStrengthData?.map((item) => {
        return { id: item.id, name: item.noOfEmployee };
      }) || [],
    [empStrengthData]
  );

  const onSubmit = async (values) => {
    const response = await tryWorkspace({
      ...values,
      userId,
      code,
      industryId: indusID,
    });
  };

  const formik = useQuestionForm(onSubmit, defaultValues);

  const { handleBlur, handleChange, handleSubmit, values, errors, touched } =
    formik;

  const handleIndustryChange = (event) => {
    const selectedIndustryId = event.target.value;
    formik.setFieldValue("industryId", selectedIndustryId);
    setIndusId(selectedIndustryId);
  };

  return (
    <AuthLayout isQuestion>
      <Div
        sx={{
          mt: "20px",
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography
          fontSize={{ lg: "24px", md: "18px", xs: "15px" }}
          color="#371B65"
          fontWeight={500}
          mb={3}
          lineHeight={"28px"}
          letterSpacing={"5%"}
          fontFamily={"Roboto"}
        >
          Setting Up Documan for you
        </Typography>
        {/* <Button
          variant="text"
          startIcon={
            <img
              src={`${ASSET_IMAGES}/backbutton.png`}
              alt="Jumbo React"
              style={{ width: "20px", height: "20px" }}
            />
          }
          sx={{
            position: "absolute",
            top: "0px",
            right: "6px",
            zIndex: 1,
            textTransform: "none",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            textAlign: "left",
            color: "#371B65",
          }}
        >
          Back
        </Button> */}
      </Div>
      <Div sx={{ width: { lg: "433px", md: "100%", xs: "100%" } }}>
        <Div
          sx={{ mt: "20px", width: { lg: "433px", md: "100%", xs: "100%" } }}
        >
          <OutlinedSecondaryTextField
            focused
            error={!!touched.workspaceName && !!errors.workspaceName}
            helperText={
              (touched.workspaceName && errors && errors.workspaceName) || ""
            }
            label="Workspace you work In?"
            variant="outlined"
            placeholder={"eg. Sanicon services"}
            style={{
              width: "100%",
            }}
            value={values.workspaceName}
            onBlur={handleBlur("workspaceName")}
            onChange={handleChange("workspaceName")}
          />
        </Div>
        <Div
          sx={{ mt: "20px", width: { lg: "433px", md: "100%", xs: "100%" } }}
        >
          <OutlinedSecondaryTextField
            focused
            select
            error={!!touched.industryId && !!errors.industryId}
            helperText={
              (touched.industryId && errors && errors.industryId) || ""
            }
            label="What industry do you work In?"
            variant="outlined"
            SelectProps={{
              displayEmpty: true,
              renderValue: (selected) => {
                if (!selected || selected.length === 0) {
                  return <em style={{ color: "#9B9B9B" }}>eg. IT</em>;
                }

                const selectedIndustry = industryList.find(
                  (item) => item.id === selected
                );
                return selectedIndustry ? selectedIndustry.name : "";
              },
            }}
            InputProps={{
              style: { color: "#9B9B9B" },
            }}
            style={{
              width: "100%",
              borderRadius: "8px",
            }}
            value={values.industryId}
            onBlur={handleBlur("industryId")}
            onChange={handleIndustryChange}
          >
            {industryList.map((industryItem) => (
              <MenuItem key={industryItem?.id} value={industryItem?.id}>
                {industryItem?.name}
              </MenuItem>
            ))}
          </OutlinedSecondaryTextField>
        </Div>
        <Div
          sx={{ mt: "20px", width: { lg: "433px", md: "100%", xs: "100%" } }}
        >
          <OutlinedSecondaryTextField
            focused
            select
            error={
              !!touched.industryDepartmentId && !!errors.industryDepartmentId
            }
            helperText={
              (touched.industryDepartmentId &&
                errors &&
                errors.industryDepartmentId) ||
              ""
            }
            label="What industry do you work in?"
            variant="outlined"
            SelectProps={{
              displayEmpty: true,
              renderValue: (selected) => {
                if (!selected || selected.length === 0) {
                  return <em style={{ color: "#9B9B9B" }}>eg. IT</em>;
                }

                const selectedIndustry = industryDeptList.find(
                  (item) => item.id === selected
                );
                return selectedIndustry ? selectedIndustry.name : "";
              },
            }}
            InputProps={{
              style: { color: "#9B9B9B" },
            }}
            style={{
              width: "100%",
              borderRadius: "8px",
            }}
            value={values.industryDepartmentId}
            onBlur={handleBlur("industryDepartmentId")}
            onChange={handleChange("industryDepartmentId")}
          >
            {industryDeptList.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </OutlinedSecondaryTextField>
        </Div>
        <Div
          sx={{ mt: "20px", width: { lg: "433px", md: "100%", xs: "100%" } }}
        >
          <OutlinedSecondaryTextField
            focused
            select
            error={!!touched.employeeStrengthId && !!errors.employeeStrengthId}
            helperText={
              (touched.employeeStrengthId &&
                errors &&
                errors.employeeStrengthId) ||
              ""
            }
            label="How big the company you are working at?"
            variant="outlined"
            style={{
              width: "100%",
              borderRadius: "8px",
            }}
            SelectProps={{
              displayEmpty: true,
              renderValue: (selected) => {
                if (!selected || selected.length === 0) {
                  return <em style={{ color: "#9B9B9B" }}>eg. IT</em>;
                }

                const selectedIndustry = EmpStrengthList.find(
                  (item) => item.id === selected
                );
                return selectedIndustry ? selectedIndustry.name : "";
              },
            }}
            value={values.employeeStrengthId}
            onBlur={handleBlur("employeeStrengthId")}
            onChange={handleChange("employeeStrengthId")}
          >
            {EmpStrengthList.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </OutlinedSecondaryTextField>
        </Div>

        {/* <Div sx={{ mt: "20px", width: "433px" }}>
          <OutlinedSecondaryTextField
            focused
            select
            error={!!touched.designation && !!errors.designation}
            helperText={
              (touched.designation && errors && errors.designation) || ""
            }
            label="What kind of work do you do?"
            variant="outlined"
            SelectProps={{
              displayEmpty: true,
              renderValue: (selected) => {
                if (!selected || selected.length === 0) {
                  return <em style={{ color: "#9B9B9B" }}>eg. software</em>;
                }

                return selected.join(", ");
              },
            }}
            InputProps={{
              style: { color: "#9B9B9B" },
            }}
            style={{
              width: "100%",
              borderRadius: "8px",
            }}
            value={values.designation}
            onBlur={handleBlur("designation")}
            onChange={handleChange("designation")}
          >
            {roleList.map((roleItem) => (
              <MenuItem key={roleItem.id} value={roleItem.id}>
                {roleItem.name}
              </MenuItem>
            ))}
          </OutlinedSecondaryTextField>
        </Div> */}
      </Div>
      <Div sx={{ mt: "25px" }}>
        <LoadingButton
          fullWidth
          variant="contained"
          size="large"
          sx={{
            mt: "23px",
            cursor: "pointer",
            padding: "5.43px, 7.24px, 5.43px, 7.24px",
            width: { lg: "433px", md: "100%", xs: "100%" },
            height: "50px",
            backgroundColor: "#371B65",
            fontFamily: "Roboto",
            fontSize: { lg: "14px", md: "12px", xs: "10px" },
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "0.05em",
            textAlign: "left",
            textTransform: "none",
          }}
          onClick={() => handleSubmit()}
          // onClick={handleGoToQuestionAir}
        >
          Continue
        </LoadingButton>
        <Div
          sx={{
            textAlign: "center",
            mt: "10px",
          }}
        >
          <Button
            variant="text"
            sx={{
              cursor: "pointer",
              color: "#371B65",
              padding: "10px 201px 0px 201",
              fontFamily: "Roboto",
              fontSize: { lg: "16px", md: "14px", xs: "12px" },
              fontWeight: 400,
              lineHeight: "24px",
              textTransform: "none",
            }}
          >
            Skip
          </Button>
        </Div>
      </Div>
    </AuthLayout>
  );
}

export default QuestionAirForm;

// import React from "react";

// function QuestionAirForm() {
//   return <div>QuestionAirForm</div>;
// }

// export default QuestionAirForm;
