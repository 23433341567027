import React from 'react';

import {packageDetail} from "./packageData";
import {Typography} from "@mui/material";
import PackageDetailItem from './PackageDetailItem';

const PackageDetailList = () => {

    return (
        <React.Fragment>
            <Typography variant={'h2'} mb={3}>Projects</Typography>
            {
                packageDetail.map((project, key) => (
                    <PackageDetailItem item={project}/>
                ))
            }
        </React.Fragment>
    );
};

export default PackageDetailList;
