import React from 'react';
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import {Card, IconButton, Typography,  ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import JumboBookmark from "@jumbo/components/JumboBookmark";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import {menuItems} from "../../data";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";

import { useNavigate } from 'react-router-dom';


const Item = styled(Span)(({theme}) => ({
    padding: theme.spacing(0, 1),
}));

const UserItem = ({user , deleteUser}) => {
    const navigate = useNavigate();
    

    const showEditUser= ()=>{
        navigate(`/dashboards/users/edit-user/${user.id}`)}

      
    return (
        <Card sx={{mb: 1}}>
            <Stack direction={"row"} alignItems={"center"} sx={{p: theme => theme.spacing(2, 1)}}>
                <Item
                    sx={{
                        flex: {xs: 1, md: '0 1 45%', lg: '0 1 35%'}
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'}>
                        <Item sx={{ml: -1}}>
                            <JumboBookmark value={user.isFavorite} sx={{verticalAlign: 'middle'}}/>
                        </Item>
                        <Item>
                            <Badge overlap="circular" variant="dot"
                                   anchorOrigin={{
                                       vertical: 'bottom',
                                       horizontal: 'right',
                                   }}
                                   sx={{
                                       '.MuiBadge-badge': {
                                           border: '2px solid #FFF',
                                           height: '14px',
                                           width: '14px',
                                           borderRadius: '50%',
                                           bgcolor: user.isOnline ? "success.main" : "#757575"
                                       }
                                   }}
                            >
                                <Avatar
                                    sx={{
                                        width: 56,
                                        height: 56
                                    }}
                                    alt={`${user.firstName} 
                                    ${user.lastName}`}
                                    src={user.profilePic}
                                />
                            </Badge>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{`${user.fullName}`}</Typography>
                            
                        </Item>
                    </Stack>
                </Item>
                <Item
                    sx={{
                      
                        flexBasis: {md: '28%', lg: '18%'},
                        display: {xs: 'none', md: 'block'}
                    }}
                >
                    <Typography variant={"h6"} mt={1} lineHeight={1.25}>{user.email}</Typography>
                </Item>
                <Item
                    sx={{
                        flexBasis: '30%',
                        display: {xs: 'none', lg: 'block'}
                    }}
                >
                  
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{user.mobile}</Typography>
                        </Item>
                      
                </Item>
                <Item
                    sx={{
                        flexBasis: '30%',
                        display: {xs: 'none', sm: 'block'}
                    }}
                >
                  <Typography variant={"h6"} mb={.5}>{user.companyName}</Typography>
                </Item>
                <Item sx={{ml: {xs: 'auto', sm: 0}}}>
               
                <JumboDdMenu menuItems={menuItems} onClickCallback={action => {
                    if (action === "edit") {
                        showEditUser();
                    } else if (action === "delete") {
                        deleteUser();
                    }
                }} />
                   
                </Item>
            </Stack>
        </Card>
    );
};

export default UserItem;

