/**
 * @format
 */

import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "@jumbo/constants/ErrorMessages";

const defaultValues = {
  mobile: "",
  firstName: "",
  lastName: "",
  email: "",
};

const schema = Yup.object().shape({
  email: Yup.string().required(ErrorMessages.login.email),
  // password: Yup.string().required(ErrorMessages.login.password),
  firstName: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .max(40)
    .required("Please enter full name"),
  mobile: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .required("Please enter a valid phone number"),
  // companyName: Yup.string().required("Please enter a valid company name"),
  // companyEmail: Yup.string().required("Please enter a valid company"),
  // companyMobile: Yup.string().required("Please enter a valid company mobile"),
  // companyAddress: Yup.string().required("Please enter a valid company address"),
  // companyLogo: Yup.string().required("Please enter a valid company logo"),
});

const useSignupForm = (onSubmit) => {
  return useFormik({
    initialValues: defaultValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: onSubmit,
  });
};

export default useSignupForm;
