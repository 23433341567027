import axios from "axios";
import { store } from "../redux/store";

const client = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

const AUTH_ROUTES = ["login", "signup", "password", "workspace-auth"];

const FILE_ROUTES = ["upload", "event-posts", "user"];

client.interceptors.request.use(
  (request) => {
    const authRoutes = AUTH_ROUTES.some((i) => {
      return request.url.includes(i);
    });
    const uploadRoutes = FILE_ROUTES.some((i) => {
      return request.url.includes(i);
    });
    const { user } = store.getState();
    const { token } = user;
    console.log("token", token);

    if (!authRoutes) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    if (uploadRoutes) {
      request.headers["Content-Type"] = "multipart/form-data";
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    if (response.data.error) {
      return Promise.reject(response.data);
    }
    return Promise.resolve(response.data);
  },
  (error) => {
    if (error.response?.status === 401) {
      // TODO: handle expired token
      const authRoutes = AUTH_ROUTES.some((i) => {
        return error.response.request.responseURL.includes(i);
      });
      if (!authRoutes) {
        localStorage.removeItem("persist:rootdata");
        window.location.href = "/";
      }
      return Promise.reject(error.response?.data);
    }
    return Promise.reject(error.response?.data);
  }
);

export default client;
