export const dataModelling = [
    {
        id: 1,
        name: 'Reverse/forward engineering',
        desc: '63 Likes, 387 Shares',
      
        bgcolor: '#38529A',
        budget: 570,
        growth: 20,
    },

    {
        id: 2,
        name: 'Conceptual, logical, physical modeling',
        desc: '43 Likes, 545 Shares',
      
        bgcolor: '#17A9FC',
        budget: 811,
        growth: -5,
    },

    {
        id: 3,
        name: 'Templates, naming conventions',
        desc: '83 Follows, 79 Likes',
      
        bgcolor: '#CC4BB7',
        budget: 685,
        growth: 20,
    },

    {
        id: 4,
        name: 'DB conversion',
        desc: '63 Likes, 387 Shares',
     
        bgcolor: '#4181ED',
        budget: 685,
        growth: 145,
    },
    {
        id: 5,
        name: 'Errors & warnings',
        desc: '70 Likes, 1387 Shares',
      
        bgcolor: '#C6171D',
        budget: 375,
        growth: 55,
    },
    {
        id: 6,
        name: 'Extended object support',
        desc: '85 Likes, 581 Shares',
      
        bgcolor: '#0073B1',
        budget: 410,
        growth: 70,
    },

    {
        id: 6,
        name: '    Auto-suggested relationships',
        desc: '85 Likes, 581 Shares',
      
        bgcolor: '#0073B1',
        budget: 410,
        growth: 70,
    },



];