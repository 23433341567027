import React, { useEffect } from "react";
import { Button } from "@mui/material";
import useRoleForm from "../hooks/useRoleForm";
import Div from "@jumbo/shared/Div";
import { TextField } from "@mui/material";
import { useRoleApiActions } from "../query/useRoleApiActions";
import useDecodedData from "app/hooks/useDecodedData";

const initialValues = {
  name: "",
  description: "",
  companyId: "",
};

const RoleForm = ({
  user,
  handleUpdateRole,
  handleCreateRole,
  hideDialog,
  refetchRole,
}) => {
  const { tryAddRole, tryEditRole } = useRoleApiActions();
  const decoded = useDecodedData();
  console.log("companyId--->", decoded);

  const onSubmit = async (values, actions) => {
    if (user) {
      await tryEditRole(values, user.id);
    } else {
      await tryAddRole({ ...values, companyId: decoded?.companyId });
    }
    actions.resetForm();
    hideDialog();
    refetchRole();
    actions.resetForm();
  };

  useEffect(() => {
    if (user) {
      setFieldValue("name", user.name || "");
      setFieldValue("description", user.description || "");
    }
  }, [user]);

  const formik = useRoleForm(onSubmit, initialValues);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    setFieldValue,
  } = formik;

  return (
    <React.Fragment>
      <Div
        sx={{
          display: "flex",
          flex: 1,
          minWidth: 0,
          alignItems: "flex-start",
          mb: 2,
        }}
      >
        <TextField
          fullWidth
          id="name"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.name}
          name="name"
          label="Enter role name"
        />
      </Div>
      <Div
        sx={{
          display: "flex",
          flex: 1,
          minWidth: 0,
          alignItems: "flex-start",
          mb: 2,
        }}
      >
        <TextField
          fullWidth
          id="description"
          multiline
          rows={4}
          size="small"
          variant="outlined"
          name="description"
          label="Description"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.description}
          defaultValue="Descriptions..."
        />
      </Div>
      <Button
        variant="contained"
        size="large"
        disableElevation
        onClick={() => handleSubmit()}
        sx={{
          mb: 1,
        }}
        disabled={isSubmitting}
      >
        Save
      </Button>
    </React.Fragment>
  );
};

export default RoleForm;
