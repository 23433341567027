import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import {
  Grid,
  Tabs,
  Tab,
  Card,
  Box,
  List,
  ListItem,
  IconButton,
  ListItemText,
} from "@mui/material";
import JumboButton from "@jumbo/components/JumboButton";
import JumboFormLabelTextField from "../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
import React, { useState, useEffect } from "react";
import * as docx from "docx-preview";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { users } from "./data";
import Div from "./../../../../@jumbo/shared/Div/Div";
import Span from "./../../../../@jumbo/shared/Span/Span";
import { ASSET_IMAGES } from "./../../../utils/constants/paths";
import CommentIcon from "@mui/icons-material/Comment";

function FileDetail() {
  const [tabIndex, setTabIndex] = useState(0);
  const { location } = window;

  const id = location.pathname?.split("/")?.[3]
    ? location.pathname?.split("/")?.[3]
    : "0";

  console.log("id", id);

  const user = users.find((user) => user.id === id);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const checkImage = (mimeType) => {
    return mimeType.startsWith("image/");
  };

  useEffect(() => {
    if (
      user?.mimeType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      PreviewWordDoc(user.fileData, new Date().getTime(), user.mimeType);
    } else if (
      user?.mimeType ===
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      PreviewPPT(user.fileData, user.mimeType);
    }
  }, [user]);

  function base64ToBlob(base64Data, contentType) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  function base64ToFile(base64Data, fileName, contentType) {
    const blob = base64ToBlob(base64Data, contentType);
    return new File([blob], fileName, { type: contentType });
  }

  function PreviewWordDoc(docBase64, filename, mimeType) {
    const fileName = filename;
    const contentType =
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    const docFile = base64ToFile(docBase64, fileName, mimeType);

    if (docFile != null) {
      const docxOptions = Object.assign(docx.defaultOptions, {
        useMathMLPolyfill: true,
      });
      const container = document.getElementById("view-image");
      if (container) {
        docx.renderAsync(docFile, container);
      }
    }
  }

  function PreviewPPT(docBase64, mimeType) {
    const container = document.querySelector("#view-image");
    if (container) {
      container.innerHTML = "";
      const iframe = document.createElement("iframe");
      iframe.style.width = "100%";
      iframe.style.height = "100vh";

      const blob = new Blob([atob(docBase64)], { type: mimeType });
      const blobURL = URL.createObjectURL(blob);
      const url = `https://docs.google.com/gview?url=${encodeURIComponent(
        blobURL
      )}&embedded=true`;

      iframe.src = url;
      container.appendChild(iframe);
    }
  }

  if (!user) {
    return <div>User not found</div>;
  }

  return (
    <DashboardLayout isFolderView={false} isFileDetail={true}>
      <Grid container spacing={3.75} sx={{ width: "100%" }}>
        <Grid item xs={12} md={12} lg={8}>
          <Card
            sx={{
              padding: { xs: "1rem", sm: "1rem", md: "0px" },
              mr: "22px",
              border: "1px solid #FCFBF6",
              backgroundColor: "#FCFBF6",
              position: "relative",
              borderRadius: "11px",
            }}
          >
            {user.docImage ? (
              <img
                src={`${user.docImage}`}
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              //  {checkImage(user.mimeType) ? (
              <img
                src={`data:image/jpeg;base64,${user.docImage}`}
                style={{ width: "100%", height: "100%" }}
              />
            )}
            {user.mimeType === "application/pdf"
              ? user.fileData && (
                  <object
                    data={`data:application/pdf;base64,${user.fileData}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                    type="application/pdf"
                  >
                    <a
                      href={`data:application/pdf;base64,${user.fileData}`}
                      target="_blank"
                    >
                      Download the PDF
                    </a>{" "}
                    instead.
                  </object>
                )
              : null}
            {(user.mimeType ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              user.mimeType ===
                "application/vnd.openxmlformats-officedocument.presentationml.presentation") && (
              <div
                style={{ width: "100%", height: "100%" }}
                id="view-image"
              ></div>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Card sx={{ borderRadius: "8px" }}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                "& .MuiTabs-indicator": {
                  backgroundColor: "#371B65",
                  color: "#371B65",
                },
              }}
              variant="fullWidth"
            >
              <Tab
                sx={{
                  textTransform: "none",
                  fontFamily: "Roboto",
                  fontSize: "13.12px",
                  fontWeight: 600,
                  lineHeight: "18.95px",
                  color: "#2E4448",
                }}
                label="Properties"
              />
              <Tab
                sx={{
                  textTransform: "none",
                  fontFamily: "Roboto",
                  fontSize: "13.12px",
                  fontWeight: 600,
                  lineHeight: "18.95px",
                  color: "#2E4448",
                }}
                label="Version"
              />
            </Tabs>
          </Card>
          <Card
            borderRadius="11px"
            sx={{
              padding: "27px 60px 20px 28px",
              border: "1px solid #FCFBF6",
              backgroundColor: "#FFFFFF",
              gap: "0px 24px",
              alignItems: "center",
            }}
          >
            {tabIndex === 0 && (
              <React.Fragment>
                <JumboFormLabelTextField
                  fullWidth
                  labelStyle={{
                    fontFamily: "Inter",
                    color: "#000000",
                    fontSize: { xs: "1.2rem", md: "16px" },
                    fontWeight: 400,
                  }}
                  label="Po Date"
                  name="poDate"
                  placeholder="Enter PO date"
                  type="text"
                  defaultValue={user.poDate}
                />
                <JumboFormLabelTextField
                  fullWidth
                  labelStyle={{
                    fontFamily: "Inter",
                    color: "#000000",
                    fontSize: { xs: "1.2rem", md: "16px" },
                    fontWeight: 400,
                  }}
                  label="Customer Number"
                  name="customerNumber"
                  placeholder="Enter customer number"
                  type="text"
                  defaultValue={user.customerNumber}
                />
                <JumboFormLabelTextField
                  fullWidth
                  labelStyle={{
                    fontFamily: "Inter",
                    color: "#000000",
                    fontSize: { xs: "1.2rem", md: "16px" },
                    fontWeight: 400,
                  }}
                  label="Expiry On"
                  name="expiryOn"
                  placeholder="Expiry On"
                  type="date"
                  defaultValue={user.expiryOn}
                />
                <JumboFormLabelTextField
                  fullWidth
                  labelStyle={{
                    fontFamily: "Inter",
                    color: "#000000",
                    fontSize: { xs: "1.2rem", md: "16px" },
                    fontWeight: 400,
                  }}
                  label="Status"
                  name="status"
                  placeholder="Enter status"
                  type="text"
                  defaultValue={user.status}
                />
                <JumboFormLabelTextField
                  fullWidth
                  labelStyle={{
                    fontFamily: "Inter",
                    color: "#000000",
                    fontSize: { xs: "1.2rem", md: "16px" },
                    fontWeight: 400,
                  }}
                  label="Premium amount"
                  name="premiumAmount"
                  placeholder="Enter amount"
                  type="text"
                  defaultValue={user.premiumAmount}
                />
                <JumboFormLabelTextField
                  fullWidth
                  labelStyle={{
                    fontFamily: "Inter",
                    color: "#000000",
                    fontSize: { xs: "1.2rem", md: "16px" },
                    fontWeight: 400,
                  }}
                  label="Plan"
                  name="plan"
                  placeholder="Enter plan"
                  type="text"
                  defaultValue={user.plan}
                />
                <JumboFormLabelTextField
                  fullWidth
                  labelStyle={{
                    fontFamily: "Inter",
                    color: "#000000",
                    fontSize: { xs: "1.2rem", md: "16px" },
                    fontWeight: 400,
                  }}
                  label="Certificate"
                  name="certificate"
                  placeholder="Enter certificate name"
                  type="text"
                  defaultValue={user.certificate}
                />
                <Box
                  sx={{
                    mt: 1,
                    ml: 0,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                    justifyContent: "end",
                  }}
                >
                  <JumboButton
                    sx={{
                      padding: "8px 16px 8px 16px",
                      gap: "8px",
                      borderRadius: " 4px ",
                      border: "1px solid #371B65",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "24px",
                      color: "#371B65",
                      textTransform: "none",
                    }}
                    variant="outlined"
                  >
                    Reset
                  </JumboButton>
                  <JumboButton
                    sx={{
                      textTransform: "none",
                      padding: "8px 16px 8px 16px",
                      gap: "8px",
                      borderRadius: " 4px ",
                      border: "1px solid #371B65",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "24px",
                      ml: "16px",
                      backgroundColor: "#371B65",
                    }}
                    variant="contained"
                  >
                    Submit
                  </JumboButton>
                </Box>
              </React.Fragment>
            )}
            {tabIndex === 1 && (
              <Div>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    p: 2,
                    bgcolor: "background.paper",
                  }}
                >
                  <ListItem disableGutters>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <ListItemText primary="Icon" />
                      </Grid>
                      <Grid item xs={8}>
                        <img
                          src={`${ASSET_IMAGES}/logo.png`}
                          alt="Jumbo React"
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem disableGutters>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <ListItemText primary="File Name" />
                      </Grid>
                      <Grid item xs={8}>
                        <ListItemText primary="Daily task tracking" />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem disableGutters>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <ListItemText primary="Version no" />
                      </Grid>
                      <Grid item xs={8}>
                        <ListItemText primary="U-123" />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem disableGutters>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <ListItemText primary="Date" />
                      </Grid>
                      <Grid item xs={8}>
                        <ListItemText primary="May 28, 2024" />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem disableGutters>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <ListItemText primary="Time" />
                      </Grid>
                      <Grid item xs={8}>
                        <ListItemText primary="4 days ago" />
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </Div>
            )}
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default FileDetail;
