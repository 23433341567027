import React from "react";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import {
  Card,
  Typography,
  TableCell,
  TableRow,
  Checkbox,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import { useNavigate } from "react-router-dom";
import { getAssetPath } from "../../../../../utils/appHelpers";
import { ASSET_IMAGES } from "../../../../../utils/constants/paths";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import JumboBookmark from "@jumbo/components/JumboBookmark";

const Item = styled(Span)(({ theme }) => ({
  //   padding: theme.spacing(0, 1),
  //   display: "flex",
  //   flexDirection: "row",
  //   alignItems: "center",
}));

const FilesListItem = ({ user, isSelected, handleCheckboxChange }) => {
  const navigate = useNavigate();

  const showEditUser = () => {
    navigate(`/dashboards/users/edit-user/${user.id}`);
  };

  return (
    <Card sx={{ mb: 1 }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isSelected}
            onChange={() => handleCheckboxChange(user.id)}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{ width: { lg: "35%", md: "35%", xs: "60%" } }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Item>
              <img
                src={getAssetPath(`${ASSET_IMAGES}/wordicon.png`, "32x32")}
                alt="pin"
                width={32}
              />
            </Item>
            <Item
              sx={{
                flex: { xs: 1, md: "0 1 45%", lg: "0 1 50%" },
                ml: 1,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: { lg: "14px", md: "12px", xs: "10px" },
                  fontWeight: 500,
                  lineHeight: "31px",
                }}
              >
                {user.fullName}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: { lg: "12px", md: "10px", xs: "8px" },
                  fontWeight: 500,
                  lineHeight: "31px",
                  color: "#A5A5A5",
                }}
              >
                100 MB
              </Typography>
            </Item>
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "column", lg: "row" }}
              alignItems="center"
              sx={{ mt: { md: 1, lg: 0 } }} // Add margin-top for md to create space when in column
            >
              <JumboBookmark
                value={user.isFavorite}
                sx={{ verticalAlign: "middle" }}
              />

              <MoreHorizIcon sx={{ verticalAlign: "middle" }} />
              <img
                src={getAssetPath(`${ASSET_IMAGES}/pin-bold.png`, "16x16")}
                alt="pin"
                width={18}
                style={{
                  verticalAlign: "middle",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
              <img
                src={getAssetPath(`${ASSET_IMAGES}/shareIcon.png`, "16x16")}
                alt="pin"
                width={16}
                style={{ verticalAlign: "middle" }}
              />
            </Box>
          </Stack>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "30%",
            display: { lg: "table-cell", md: "table-cell", xs: "none" },
          }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Typography
              // variant={"h6"}
              // mt={1}
              // lineHeight={1.25}
              sx={{
                fontSize: { lg: "12px", md: "10px", xs: "8px" },
                fontWeight: 500,
                lineHeight: "16px",
                letterSpacing: " 0.025em",
                color: "#9CA3AF",
              }}
            >
              You opened
            </Typography>
            <img
              src={getAssetPath(`${ASSET_IMAGES}/pointIcon.png`, "4x4")}
              alt="pin"
              width={4}
              style={{ marginLeft: "13px", marginRight: "13px" }}
            />
            {/* <FiberManualRecordIcon fontSize="sm" /> */}
            <Typography
              variant={"h6"}
              mt={1}
              lineHeight={1.25}
              sx={{
                fontSize: { lg: "12px", md: "10px", xs: "8px" },
                fontWeight: 500,
                lineHeight: "16px",
                letterSpacing: " 0.025em",
                color: "#9CA3AF",
              }}
            >
              Apr 16, 2024
              {/* {user.email} */}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell
          align="Left"
          sx={{
            width: "15%",
            display: { lg: "table-cell", xs: "none", md: "table-cell" },
          }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Item>
              <Avatar
                sx={{
                  width: 34,
                  height: 34,
                }}
                alt={`${user.firstName} 
                                    ${user.lastName}`}
                src={user.profilePic}
              />
            </Item>
            <Typography
              sx={{
                marginLeft: 1.5,
                fontSize: { lg: "12px", md: "10px", xs: "8px" },
                fontWeight: 500,
                lineHeight: "16px",
                letterSpacing: " 0.025em",
                color: "#9CA3AF",
              }}
            >
              me
              {/* {user.mobile} */}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell
          align="left"
          sx={{ width: { lg: "20%", md: "20%", xs: "40%" } }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Item>
              <img
                src={getAssetPath(`${ASSET_IMAGES}/driveIcon.png`, "24x24")}
                alt="pin"
                width={24}
              />
            </Item>
            <Item>
              {" "}
              <Typography
                variant={"h6"}
                mb={0.5}
                sx={{
                  ml: 1,
                  fontSize: { lg: "12px", md: "10px", xs: "8px" },
                  fontWeight: 500,
                  lineHeight: "16px",
                  letterSpacing: " 0.025em",
                  color: "#9CA3AF",
                }}
              >
                My Drive
                {/* {user.companyName} */}
              </Typography>
            </Item>
          </Stack>
        </TableCell>
      </TableRow>
    </Card>
  );
};

export default FilesListItem;
