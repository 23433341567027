import React from 'react';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import JumboCardFeatured from "@jumbo/components/JumboCardFeatured";
// import {ASSET_IMAGES} from "../../../utils/constants/paths";
// import {getAssetPath} from "../../../utils/appHelpers";
import { getAssetPath } from './../../../../utils/appHelpers';
import { ASSET_IMAGES } from './../../../../utils/constants/paths';

const ModellingCard = ({height}) => {
    return (
        <JumboCardFeatured
          
            tickSx={{bgcolor: 'success.main'}}
            direction={"column"}
            textAlign={"center"}
           
        >
            <Typography variant={"h1"} mb={2}>Try Modelling</Typography>
            <Typography mb={3}>Learn with experts from around the world. Some description about the card can be
                found below.</Typography>
            <Button variant={"contained"}>Get Started</Button>
        </JumboCardFeatured>
    );
};
/* Todo height prop define */
export default ModellingCard;