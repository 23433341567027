/**
 * @format
 */
import { useQuery } from "react-query";
import { fetchAllRoles } from "../../../../services/pages.services";

import { QueryKeys } from "../../../../utils/QueryKeys";

async function getAllRoles(pageNo, limit) {
  try {
    const offset = pageNo * limit;
    const response = await fetchAllRoles(offset, limit);
    console.log("roles data", response);
    if (response) {
      return response;
    }
    return {
      statusCode: 404,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

const useFetchAllRoles = (pageNo, limit = 10, enabled = true) => {
  const cacheKey = [QueryKeys.role];
  return useQuery(
    cacheKey,
    () => {
      return getAllRoles(pageNo, limit);
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );
};

export { useFetchAllRoles };
