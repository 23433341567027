import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Stack, Box } from "@mui/material";
import PricePlan from "./../../extra-pages/PricingPlan/PricePlan";
import FeaturesList from "./../../extra-pages/PricingPlan/FeaturesList";
import PackageDetailList from "./components/PackageDetail";
import DocumentCard from "./components/DocumentCard";
import SponsoredCard from "./components/SponsoredCard";
import BrandsCard from "./components/BrandCard";
import ModellingCard from "./components/Modelling";
import FrequentlyAsKQuestion from "./components/FQA/index";
import Div from "./../../../../@jumbo/shared/Div/Div";
import Span from "./../../../../@jumbo/shared/Span/Span";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Packages = ({ scrollHeight }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 9000);
    };

    fetchData();
  }, []);

  return (
    <>
      <Div
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontFamily: "Roboto",
              fontSize: "24px",
              fontWeight: 500,
              lineHeight: "28.13px",
              textAlign: "left",
              mb: "12px",
            }}
          >
            Try out the best of Documan for free
          </Typography>
        </Div>

        <Typography
          sx={{
            color: "#363636",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "14px",
            textAlign: "left",
          }}
        >
          We’ll remind you when you have a few days left in your trial
        </Typography>
        <Button
          variant="text"
          sx={{
            position: "absolute",
            top: "0px",
            right: "10px",
            zIndex: 1,
            textTransform: "none",
            fontFamily: "Roboto",
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "24px",
            textAlign: "left",
            color: "#371B65",
          }}
        >
          Skip
        </Button>
      </Div>

      <Grid container spacing={3.75} mt={1}>
        <Grid item xs={12} md={6} lg={4}>
          <PricePlan
            title={"Rs. 150"}
            sx={{
              borderRadius: "20px",
              //   width: "377px",
              height: "550px",
              gap: "0px",
              opacity: "0px",
              boxShadow: "0px 4px 20px 0px #A9A9A940",
            }}
            subheader={"Personal"}
            headerSx={{
              textAlign: "center",
              bgcolor: " #371B65",
            }}
          >
            <FeaturesList isLoading={isLoading} />
            <Button
              variant={"contained"}
              color={"warning"}
              disableElevation
              sx={{
                mt: "10px",
                backgroundColor: "#371B65",
                width: "149px",
                height: "50px",
                padding: " 5.43px 7.24px 5.43px 7.24px",
                gap: " 7.24px",
                borderRadius: "3.62px 0px 0px 0px",
                opacity: "0px",
              }}
            >
              Buy Now
            </Button>
          </PricePlan>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PricePlan
            title={"Rs. 500"}
            sx={{
              borderRadius: "20px",
              //   width: "377px",
              height: "550px",
              gap: "0px",
              opacity: "0px",
              boxShadow: "0px 4px 20px 0px #A9A9A940",
              transform: "scale(1)",
            }}
            subheader={"Business"}
            headerSx={{
              textAlign: "center",

              bgcolor: "#371B65",
            }}
          >
            <FeaturesList isLoading={isLoading} isMiddle />
            <Button
              variant={"contained"}
              color={"warning"}
              disableElevation
              sx={{
                mt: "10px",
                backgroundColor: "#371B65",
                width: "149px",
                height: "50px",
                padding: " 5.43px 7.24px 5.43px 7.24px",
                gap: " 7.24px",
                borderRadius: "3.62px 0px 0px 0px",
                opacity: "0px",
              }}
            >
              Buy Now
            </Button>
          </PricePlan>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <PricePlan
            title={"Rs. 250"}
            sx={{
              borderRadius: "20px",
              //   width: "377px",
              height: "550px",
              gap: "0px",
              opacity: "0px",
              boxShadow: "0px 4px 20px 0px #A9A9A940",
            }}
            subheader={"Professional"}
            headerSx={{
              textAlign: "center",
              bgcolor: "#371B65",
            }}
          >
            <FeaturesList isLoading={isLoading} />
            <Button
              variant={"contained"}
              color={"warning"}
              disableElevation
              sx={{
                mt: "10px",
                backgroundColor: "#371B65",
                width: "149px",
                height: "50px",
                padding: " 5.43px 7.24px 5.43px 7.24px",
                gap: " 7.24px",
                borderRadius: "3.62px 0px 0px 0px",
                opacity: "0px",
              }}
            >
              Buy Now
            </Button>
          </PricePlan>
        </Grid>
      </Grid>

      <Stack direction="row" display="flex" justifyContent="center" mt={4}>
        <Button
          variant="text"
          mt={4}
          endIcon={<ExpandMoreIcon />}
          sx={{
            dispaly: "flex",
            fontSize: "18px",
            justifyContent: "center",
          }}
        >
          Show details
        </Button>
      </Stack>

      <PackageDetailList />
      <Stack mt={10}>
        <DocumentCard />
      </Stack>
      <Box mt={10}>
        <SponsoredCard />
      </Box>

      <Box mt={10}>
        <Grid container spacing={3.75} mb={4}>
          <Grid item xs={12} md={6} lg={4}>
            <PricePlan
              title={
                <Typography
                  variant={"h5"}
                  color="inherit"
                  sx={{
                    textTransform: "uppercase",
                    letterSpacing: 3,
                  }}
                >
                  Personal
                </Typography>
              }
              subheader={
                <Typography
                  variant={"h2"}
                  fontSize={52}
                  fontWeight={500}
                  mb={0}
                  color="inherit"
                >
                  <Span
                    sx={{
                      fontSize: "50%",
                      verticalAlign: "super",
                      fontWeight: "400",
                      mr: 0.5,
                    }}
                  >
                    $
                  </Span>
                  25
                </Typography>
              }
              headerSx={{
                bgcolor: "common.black",
              }}
            >
              <FeaturesList />
              <Button
                variant={"contained"}
                disableElevation
                fullWidth
                size={"large"}
                sx={{
                  mb: 2,
                  borderRadius: 2,
                  maxWidth: 260,
                  bgcolor: "common.black",
                }}
              >
                Buy Now
              </Button>
            </PricePlan>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <PricePlan
              title={
                <Typography
                  variant={"h5"}
                  color="inherit"
                  sx={{
                    textTransform: "uppercase",
                    letterSpacing: 3,
                  }}
                >
                  Personal
                </Typography>
              }
              subheader={
                <Typography
                  variant={"h2"}
                  fontSize={52}
                  fontWeight={500}
                  mb={0}
                  color="inherit"
                >
                  <Span
                    sx={{
                      fontSize: "50%",
                      verticalAlign: "super",
                      fontWeight: "400",
                      mr: 0.5,
                    }}
                  >
                    $
                  </Span>
                  25
                </Typography>
              }
              headerSx={{
                bgcolor: "common.black",
              }}
            >
              <FeaturesList />
              <Button
                variant={"contained"}
                disableElevation
                fullWidth
                size={"large"}
                sx={{
                  mb: 2,
                  borderRadius: 2,
                  maxWidth: 260,
                  bgcolor: "common.black",
                }}
              >
                Buy Now
              </Button>
            </PricePlan>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <PricePlan
              title={
                <Typography
                  variant={"h5"}
                  color="inherit"
                  sx={{ textTransform: "uppercase", letterSpacing: 3 }}
                >
                  Professional
                </Typography>
              }
              subheader={
                <Typography
                  variant={"h2"}
                  fontSize={52}
                  fontWeight={500}
                  mb={0}
                  color="inherit"
                >
                  <Span
                    sx={{
                      fontSize: "50%",
                      verticalAlign: "super",
                      fontWeight: "400",
                      mr: 0.5,
                    }}
                  >
                    $
                  </Span>
                  49
                </Typography>
              }
              headerSx={{ bgcolor: "common.black" }}
            >
              <FeaturesList />
              <Button
                variant={"contained"}
                disableElevation
                fullWidth
                size={"large"}
                sx={{
                  mb: 2,
                  borderRadius: 2,
                  maxWidth: 260,
                  bgcolor: "common.black",
                }}
              >
                Buy Now
              </Button>
            </PricePlan>
          </Grid>
        </Grid>
      </Box>
      <Box mt={10}>
        <BrandsCard />
      </Box>
      <Box mt={10}>
        <ModellingCard />
      </Box>
      <Box mt={10}>
        <FrequentlyAsKQuestion />
      </Box>
    </>
  );
};

export default Packages;
