import React,{useState, useEffect} from 'react';
import JumboCustomLoader  from '@jumbo/components/JumboLoader';
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import {company} from "../../data";
import CompanyItem from "./CompanyItem";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../../../layouts/shared/headers/PageHeader/PageHeader";
import {useNavigate} from "react-router-dom";



const CompanyList = () => {
    const navigate = useNavigate();
    const {showDialog, hideDialog} = useJumboDialog();
    const [companyList, setCompanyList] = useState(company);
    const [isLoading, setIsLoading] = useState(true); 

    useEffect(() => {    
        const fetchData = () => {
            setTimeout(() => {
                setIsLoading(false); 
            }, 9000); 
        };
  
        fetchData();
    }, []);
  

   
   const handleAddUser =()=>{
    navigate(`/dashboards/company/add-company`)}

    const handleDeleteUser = (userId) => {
        showDialog({
            variant: 'confirm',
            title: 'Are you sure about deleting this contact?',
            content: "You won't be able to recover this contact later",
            onYes: () => {
                setCompanyList(prevUsers => prevUsers.filter(user => user.id !== userId));
                hideDialog(); 
            },
            onNo: hideDialog
        })
       
    };
   
    return (
       
            <JumboContentLayout             
                header={
                    <PageHeader
                        title={"Company"}
                        showAddContactDialog = {handleAddUser}
                        isAddButton      
                        buttonText={"Add Company"}                
                    />
                }        
            >
               
               {isLoading ? ( 
                <JumboCustomLoader isList={true} isCustom={false}/>
            ) : (
                companyList.map((user, index) => (
                    <CompanyItem user={user} key={index} deleteUser={() => handleDeleteUser(user.id)}/>
                    )))
                }
             </JumboContentLayout>
       
    );
};
export default CompanyList;



