import {ASSET_AVATARS} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";

export const menuItems = [
    {
        icon:null,
        title: "View",
        slug: "all",
    },
    {
        icon:null,
        title: "Edit",
        slug: "edit",
    },
    {
        icon:null,
        title: "Delete",
        slug: "delete",
    }
];



export const users = [
    {
        id:"U_123",
        profilePic: getAssetPath(`${ASSET_AVATARS}/avatar3.jpg`,"56x56"),
        fullName: "Jannie Thompson",
     
        mobile:"925867546",
   
        email: "jannie@gmail.com",
        password: "12346",
companyName: "Sanicon Services",
companyEmail: "saniconService@gmail.com",
companyMobile: "4564321",
companyAddress: "XYz"
      
    },
    {
        id:"U_124",
        profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`,"56x56"),
        fullName: "Eliza Shelton",
         mobile:"925867546",
        email: "jannie@gmail.com",
        companyName: "Sanicon Services",
        companyEmail: "saniconService@gmail.com",
        companyMobile: "4564321",
        companyAddress: "XYz"
    },
    {
        id:"U_125",
        profilePic: getAssetPath(`${ASSET_AVATARS}/avatar5.jpg`,"56x56"),
        fullName: "Irene Owen",
      
        mobile:"925867546",
        email: "jannie@gmail.com",
        companyName: "Sanicon Services",
        companyEmail: "saniconService@gmail.com",
        companyMobile: "4564321",
        companyAddress: "XYz"
    },
    {
        id:"U_126",
        profilePic: getAssetPath(`${ASSET_AVATARS}/avatar6.jpg`,"56x56"),
        fullName: "Irene Owen",
      
        mobile:"925867546",
        email: "jannie@gmail.com",
        companyName: "Sanicon Services",
        companyEmail: "saniconService@gmail.com",
        companyMobile: "4564321",
        companyAddress: "XYz"
    },
    {
        id:"U_127",
        profilePic: getAssetPath(`${ASSET_AVATARS}/avatar7.jpg`,"56x56"),
        fullName: "Irene Owen",
      
        mobile:"925867546",
        email: "jannie@gmail.com",
        companyName: "Sanicon Services",
        companyEmail: "saniconService@gmail.com",
        companyMobile: "4564321",
        companyAddress: "XYz"
    },
    {
        id:"U_128",
        profilePic: getAssetPath(`${ASSET_AVATARS}/avatar8.jpg`,"56x56"),
        fullName: "Irene Owen",
      
        mobile:"925867546",
        email: "jannie@gmail.com",
        companyName: "Sanicon Services",
        companyEmail: "saniconService@gmail.com",
        companyMobile: "4564321",
        companyAddress: "XYz"
    },
    {
        id:"U_129",
        profilePic: getAssetPath(`${ASSET_AVATARS}/avatar9.jpg`,"56x56"),
        fullName: "Irene Owen",
      
        mobile:"925867546",
        email: "jannie@gmail.com",
        companyName: "Sanicon Services",
        companyEmail: "saniconService@gmail.com",
        companyMobile: "4564321",
        companyAddress: "XYz"
    },
    {
        id:"U_129",
        profilePic: getAssetPath(`${ASSET_AVATARS}/avatar10.jpg`,"56x56"),
        fullName: "Irene Owen",
      
        mobile:"925867546",
        email: "jannie@gmail.com",
        companyName: "Sanicon Services",
        companyEmail: "saniconService@gmail.com",
        companyMobile: "4564321",
        companyAddress: "XYz"
    },
    {
        id:"U_130",
        profilePic: getAssetPath(`${ASSET_AVATARS}/avatar11.jpg`,"56x56"),
        fullName: "Irene Owen",
        mobile:"925867546",
        email: "jannie@gmail.com",
        companyName: "Sanicon Services",
        companyEmail: "saniconService@gmail.com",
        companyMobile: "4564321",
        companyAddress: "XYz"
    },
];
