import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/es/storage";

import user from "./user/auth";
import userData from "./userData/userData.slice";

const persistConfig = {
  key: "rootdata",
  storage,
};

const combinedReducer = combineReducers({
  user,
  userData,
});

const persistedReducer = persistReducer(persistConfig, combinedReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export const RootState = store.getState;
export const AppDispatch = store.dispatch;

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;

export { persistor, store };
