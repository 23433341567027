import client from "./client";
import API_URLS from "./endPoints";

async function addRole(value) {
 
  return client.post(API_URLS.ROLE, value);
}

async function editRole(value , id) {
    return client.put(`${API_URLS.ROLE}/${id}`, value);
  }

  async function addUser(values) {
    return client.post(API_URLS.ADD_USER, values);
  }
  
  async function editUser(id, values) {
    return client.put(`${API_URLS.UPDATE_USER_BY_ID}/${id}`, values);
  }

  async function fetchAllRoles(page, limit) {
    return client.get(
      `${API_URLS.ROLE}`,
    );
  }

  async function deleteRole(id) {
    return client.delete(`${API_URLS.ROLE}/${id}`);
  }




export {
    editUser,
    addUser,
    addRole,
    fetchAllRoles,
    editRole,
    deleteRole

};