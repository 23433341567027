import React from "react";
import { useDropzone } from "react-dropzone";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import { ListItem } from "@mui/material";
import JumboCard from "./../../../../@jumbo/components/JumboCard/JumboCard";
import DndWrapper from "./components/DndWrapper";
import UploadList from "./components/UploadList/UploadList";

const UploadDoc = () => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <ListItem selected key={file.path} sx={{ width: "auto", mr: 1 }}>
      {file.path} - {file.size} bytes
    </ListItem>
  ));

  return (
    <>
      <DndWrapper>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Typography variant={"body1"}>
            Drag 'n' drop some files here, or click to select files
          </Typography>
        </div>
      </DndWrapper>
      <Typography variant={"h4"}>Files</Typography>
      <List disablePadding sx={{ display: "flex" }}>
        {files}
      </List>
      <UploadList />
    </>
  );
};

export default UploadDoc;
