/**
 * @format
 */
const QueryKeys = {
  users: "teamUsers",
  dashboard: "dashboard",
  document: "document",
  addDocument: "addDocument",
  addUser: "addUser",
  getUser: "getUser",
  docProperty: "docProperty",
  role: "role",
  container: "container",
  group: "group",
  containerHierarchy: "containerHierarchy",
  viewdocumentbyid: "viewdocumentbyid",
  manageList: "manageList",
  manageGroupList: "manageGroupList",
  profile: "profile",
  documentCount: "documentCount",
  industry: "industry",
  industryDept: "industryDept",
};

export { QueryKeys };
