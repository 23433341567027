import React from "react";
import { Grid, Button, ButtonGroup } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { getAssetPath } from "./../../../utils/appHelpers";
import { ASSET_IMAGES } from "./../../../utils/constants/paths";
import DocumentSortCard from "./../DocumentSortCard/index";
import SearchGlobal from "./../../../shared/SearchGlobal/SearchGlobal";
import { useNavigate } from "react-router-dom";
import JumboButton from "@jumbo/components/JumboButton";

function FileButton({ isFolderView, isAllFiles, isShareFiles }) {
  const navigate = useNavigate();
  const handleGoToGridView = () => {
    navigate("grid-view");
  };
  return (
    <Grid container>
      {isAllFiles && (
        <Grid item xs={12} sm={12} lg={12}>
          <Div sx={{ display: "flex", justifyContent: "space-between" }}>
            <Div>
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button
                  startIcon={
                    <img
                      src={getAssetPath(`${ASSET_IMAGES}/files.png`, "18x18")}
                      alt="pin"
                      width={18}
                    />
                  }
                  sx={{
                    width: "91px",
                    height: "32px",
                    padding: "6px 16px 6px 16px",
                    gap: "8px",
                    backgroundColor: "#371B65",
                    textTransform: "none",
                    fontFamily: "Roboto",
                    fontSize: { lg: "14px", md: "12px", xs: "12px" },
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: "0.05em",
                  }}
                >
                  Files
                </Button>
                <Button
                  variant="outlined"
                  startIcon={
                    <img
                      src={getAssetPath(`${ASSET_IMAGES}/folder.png`, "18x18")}
                      alt="pin"
                      width={18}
                    />
                  }
                  sx={{
                    width: "101px",
                    height: "32px",
                    padding: "6px, 16px, 6px, 16px",
                    gap: "8px",
                    color: "#371B65",
                    border: "1px solid #371B65",
                    textTransform: "none",
                    fontFamily: "Roboto",
                    fontSize: { lg: "14px", md: "12px", xs: "12px" },
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: "0.05em",
                  }}
                >
                  Folder
                </Button>
              </ButtonGroup>
            </Div>
            <Div>
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                <JumboButton
                  variant="outlined"
                  onClick={isFolderView ? handleGoToGridView : null}
                  sx={{
                    height: "32px",
                    padding: "6px, 16px, 6px, 16px",
                    gap: "8px",
                    color: "#371B65",
                    border: "1px solid #371B65",
                    textTransform: "none",
                    fontFamily: "Roboto",
                    fontSize: { lg: "14px", md: "12px", xs: "12px" },
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: "0.05em",
                    textAlign: "left",
                    cursor: isFolderView ? "pointer" : "not-allowed",
                  }}
                >
                  Grid View
                </JumboButton>
                <JumboButton
                  sx={{
                    //   width: "95px",
                    height: "32px",
                    padding: "6px 16px 6px 16px",
                    gap: "8px",
                    backgroundColor: "#371B65",
                    textTransform: "none",
                    fontFamily: "Roboto",
                    fontSize: { lg: "14px", md: "12px", xs: "12px" },
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: "0.05em",
                    textAlign: "left",
                  }}
                >
                  List View
                </JumboButton>
              </ButtonGroup>
            </Div>
          </Div>
        </Grid>
      )}
      {/* <Grid item xs={12} md={12} lg={12} sx={{ mt: "11px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "60px",
              alignItems: "center",
              px: "5px",
              gap: "24.18px",
              borderRadius: "8px",
              border: " 0.76px solid #371B6566",
            }}
          >
            <DocumentSortCard
              Iconsrc={getAssetPath(`${ASSET_IMAGES}/allFileIcon.png`, "17x15")}
              title="All Files"
              subtitle="100 MB"
            />
            <DocumentSortCard
              Iconsrc={getAssetPath(`${ASSET_IMAGES}/wordicon.png`, "17x15")}
              title="21 Word file"
              subtitle="100 MB"
            />
            <DocumentSortCard
              Iconsrc={getAssetPath(`${ASSET_IMAGES}/xcelicon.png`, "17x15")}
              title="24 Excel file"
              subtitle="100 MB"
            />
            <DocumentSortCard
              Iconsrc={getAssetPath(`${ASSET_IMAGES}/ppticon.png`, "17x15")}
              title="12 Powerpoint file"
              subtitle="100 MB"
            />
            <DocumentSortCard
              Iconsrc={getAssetPath(`${ASSET_IMAGES}/pdfIcon.png`, "17x15")}
              title="12 PDF"
              subtitle="100 MB"
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {isAllFiles && (
              <JumboButton
                variant="text"
                sx={{
                  textTransform: "none",
                  fontFamily: "Roboto",
                  fontSize: { lg: "14px", md: "12px" },
                  fontWeight: 400,
                  lineHeight: "31px",
                  textAlign: "left",
                  color: "#000000",
                }}
                startIcon={
                  <img
                    src={getAssetPath(
                      `${ASSET_IMAGES}/sortSymbol.png`,
                      "24x24"
                    )}
                    alt="pin"
                    width={18}
                  />
                }
                endIcon={
                  <img
                    src={getAssetPath(`${ASSET_IMAGES}/more.png`, "18x18")}
                    alt="pin"
                    width={18}
                  />
                }
              >
                Sort
              </JumboButton>
            )}
            {isShareFiles && (
              <div>
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                >
                  <JumboButton
                    sx={{
                      width: "85px",
                      height: "46px",
                      padding: "6px 16px",
                      gap: "8px",
                      backgroundColor: "#371B65",
                      textTransform: "none",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      letterSpacing: "0.05em",
                      textAlign: "left",
                    }}
                  >
                    With you
                  </JumboButton>
                  <JumboButton
                    variant="outlined"
                    sx={{
                      width: "85px",
                      height: "46px",
                      padding: "6px 16px",
                      gap: "8px",
                      color: "#371B65",
                      border: "1px solid #371B65",
                      textTransform: "none",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      letterSpacing: "0.05em",
                      textAlign: "left",
                    }}
                  >
                    By You
                  </JumboButton>
                </ButtonGroup>
              </div>
            )}
            <SearchGlobal
              sx={{
                maxWidth: { xs: 240, md: 300 },
                ml: "10px",
              }}
            />
          </Box>
        </Box>
      </Grid> */}
      <Grid item xs={12} md={12} lg={12} sx={{ mt: "11px" }}>
        <Grid container justifyContent="space-between">
          <Grid
            item
            container
            xs={12}
            md={9}
            lg={7}
            xl={7}
            sx={{
              display: "flex",
              justifyContent: { xs: "space-between", lg: "space-between" },
              height: "60px",
              alignItems: "center",
              px: "5px",
              // gap: { lg: "24.18px" },
              borderRadius: "8px",
              border: "0.76px solid #371B6566",
            }}
          >
            <DocumentSortCard
              Iconsrc={getAssetPath(`${ASSET_IMAGES}/allFileIcon.png`, "17x15")}
              title="All Files"
              subtitle="100 MB"
            />
            <DocumentSortCard
              Iconsrc={getAssetPath(`${ASSET_IMAGES}/wordicon.png`, "17x15")}
              title="21 Word file"
              subtitle="100 MB"
            />
            <DocumentSortCard
              Iconsrc={getAssetPath(`${ASSET_IMAGES}/xcelicon.png`, "17x15")}
              title="24 Excel file"
              subtitle="100 MB"
            />
            <DocumentSortCard
              Iconsrc={getAssetPath(`${ASSET_IMAGES}/ppticon.png`, "17x15")}
              title="12 Powerpoint file"
              subtitle="100 MB"
            />
            <DocumentSortCard
              Iconsrc={getAssetPath(`${ASSET_IMAGES}/pdfIcon.png`, "17x15")}
              title="12 PDF"
              subtitle="100 MB"
            />
          </Grid>
          <Grid
            xs={12}
            md={12}
            lg={4}
            item
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: { md: "11px", xs: "11px" },
            }}
          >
            {isAllFiles && (
              <JumboButton
                variant="text"
                sx={{
                  textTransform: "none",
                  fontFamily: "Roboto",
                  fontSize: { lg: "14px", md: "12px" },
                  fontWeight: 400,
                  lineHeight: "31px",
                  textAlign: "left",
                  color: "#000000",
                }}
                startIcon={
                  <img
                    src={getAssetPath(
                      `${ASSET_IMAGES}/sortSymbol.png`,
                      "24x24"
                    )}
                    alt="pin"
                    width={18}
                  />
                }
                endIcon={
                  <img
                    src={getAssetPath(`${ASSET_IMAGES}/more.png`, "18x18")}
                    alt="pin"
                    width={18}
                  />
                }
              >
                Sort
              </JumboButton>
            )}
            {isShareFiles && (
              <div>
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                >
                  <JumboButton
                    sx={{
                      width: "85px",
                      height: "46px",
                      padding: "6px 16px",
                      gap: "8px",
                      backgroundColor: "#371B65",
                      textTransform: "none",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      letterSpacing: "0.05em",
                      textAlign: "left",
                    }}
                  >
                    With you
                  </JumboButton>
                  <JumboButton
                    variant="outlined"
                    sx={{
                      width: "85px",
                      height: "46px",
                      padding: "6px 16px",
                      gap: "8px",
                      color: "#371B65",
                      border: "1px solid #371B65",
                      textTransform: "none",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      letterSpacing: "0.05em",
                      textAlign: "left",
                    }}
                  >
                    By You
                  </JumboButton>
                </ButtonGroup>
              </div>
            )}
            <SearchGlobal
              sx={{
                maxWidth: { xs: 240, md: 300 },
                ml: "10px",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FileButton;
