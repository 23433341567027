import { getAssetPath } from "./../../../utils/appHelpers";
import { ASSET_AVATARS, ASSET_IMAGES } from "./../../../utils/constants/paths";

export const menuItems = [
  {
    icon: null,
    title: "View",
    slug: "all",
  },
  {
    icon: null,
    title: "Edit",
    slug: "edit",
  },
  {
    icon: null,
    title: "Delete",
    slug: "delete",
  },
];

export const users = [
  {
    id: "U_123",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "56x56"),
    fullName: "Jannie Thompson",
    modified: "Apr 16, 2024",
    docName: "Daily UI Tracking word file",
    modifiedBy: "Nijin Varghese",
    mobile: "925867546",
    itemCount: "0",
    sharing: "Private",
    email: "jannie@gmail.com",
    password: "12346",
    mimeType: ".docx",
    docImage: getAssetPath(
      `${ASSET_IMAGES}/wall/ethan-robertson.jpg`,
      "640x420"
    ),
  },
  {
    id: "U_124",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "56x56"),
    fullName: "Eliza Shelton",
    modified: "Apr 16, 2024",
    docName: "Daily UI Tracking word file",
    modifiedBy: "Nijin Varghese",
    itemCount: "4",
    sharing: "Private",
    mobile: "925867546",
    email: "jannie@gmail.com",
    mimeType: ".docx",
    docImage: getAssetPath(
      `${ASSET_IMAGES}/wall/ethan-robertson.jpg`,
      "640x420"
    ),
  },
];
