import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Card,
  useMediaQuery,
  Checkbox,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import SortedTableItem from "./SortedTableItem";
import { users } from "../data";

import TablePagination from "@mui/material/TablePagination";
import FilesListItem from "./FileListItem";
import JumboTableHead from "@jumbo/components/JumboTableHead/JumboTableHead";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";

function FilesList({ selectedUsers, onDocumentSelect }) {
  const [userList, setUserList] = useState(users);
  //   const [selectedUsers, setSelectedUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const isMediumScreen = useMediaQuery("(min-width:768px)");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((user) => user.id);
      onDocumentSelect(newSelecteds);
      return;
    }
    onDocumentSelect([]);
  };

  const handleCheckboxChange = (id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    onDocumentSelect(newSelected);
  };

  const isSelected = (id) => selectedUsers.indexOf(id) !== -1;

  return (
    <Table>
      <Card sx={{ mb: 1 }}>
        {" "}
        <TableHead>
          <TableRow>
            <JumboTableHead padding="checkbox">
              <Checkbox
                indeterminate={
                  selectedUsers.length > 0 &&
                  selectedUsers.length < userList.length
                }
                checked={
                  userList.length > 0 &&
                  selectedUsers.length === userList.length
                }
                onChange={handleSelectAllClick}
              />
            </JumboTableHead>
            <JumboTableHead
              align="left"
              style={{ width: isLargeScreen || isMediumScreen ? "40%" : "60%" }}
            >
              <TableSortLabel>
                NAME
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </JumboTableHead>
            <JumboTableHead
              align="left"
              style={{
                width: "15%",
                display:
                  isLargeScreen || isMediumScreen ? "table-cell" : "none",
              }}
            >
              <TableSortLabel>
                MODIFIED
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </JumboTableHead>
            <JumboTableHead
              align="left"
              style={{
                width: "18%",
                display:
                  isLargeScreen || isMediumScreen ? "table-cell" : "none",
              }}
            >
              <TableSortLabel>
                MODIFIED BY
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </JumboTableHead>
            <JumboTableHead
              align="left"
              style={{
                width: "12%",
                display:
                  isLargeScreen || isMediumScreen ? "table-cell" : "none",
              }}
            >
              <TableSortLabel>
                FILE SIZE
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </JumboTableHead>
            {/* <JumboTableHead align="left" style={{ width: "10%" }}>
              <TableSortLabel>
                SHARING
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </JumboTableHead> */}
            <JumboTableHead align="left" style={{ width: "15%" }}>
              <TableSortLabel>
                ACTIVITY
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </JumboTableHead>
          </TableRow>
        </TableHead>
      </Card>

      <TableBody>
        {userList.map((user, index) => (
          <FilesListItem
            user={user}
            key={index}
            isSelected={isSelected(user.id)}
            handleCheckboxChange={handleCheckboxChange}
            // deleteUser={() => handleDeleteUser(user.id)}
          />
        ))}
      </TableBody>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={userList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Table>
  );
}

export default FilesList;
