import React from "react";
import JumboNavIdentifier from "@jumbo/components/JumboVerticalNavbar/JumboNavIdentifier";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import { SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import List from "@mui/material/List";
import PropTypes from "prop-types";
import Div from "@jumbo/shared/Div";
import Span from "@jumbo/shared/Span";
import { LinearProgress } from "@mui/material";
import { Button, Typography } from "@mui/material";
import { getAssetPath } from "./../../../app/utils/appHelpers";
import { ASSET_IMAGES } from "./../../../app/utils/constants/paths";
import JumboButton from "../JumboButton";

const JumboVerticalNavbar = ({ items }) => {
  const { sidebarOptions } = useJumboLayoutSidebar();

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  return (
    <>
      <List
        disablePadding
        sx={{
          mr: isMiniAndClosed ? 0 : 2,
          pb: 1,
        }}
      >
        {items.map((item, index) => (
          <JumboNavIdentifier translate item={item} key={index} />
        ))}
      </List>
      <Div sx={{ padding: "26px 24px 0px 22px " }}>
        <Div sx={{ backgroundColor: "#F6F6F6", padding: "9px 14px 9px 14px" }}>
          <Div sx={{ display: "flex" }}>
            <img
              src={getAssetPath(`${ASSET_IMAGES}/storageIcon.png`, "20x20")}
              alt="pin"
              width={20}
            />
            <Typography
              sx={{
                ml: "10px",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "20px",
                color: "black",
              }}
            >
              Storage{" "}
              <Span
                sx={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "20px",

                  color: " #1C1C1CCC",
                }}
              >
                (83% full)
              </Span>
            </Typography>
            {/* <Span sx={{ fontSize: "14px" }}>Storage</Span>
            <Span style={{ fontSize: "14px" }}>83% full</Span> */}
          </Div>
          <Div sx={{ mt: "10px" }}>
            <LinearProgress
              variant="determinate"
              value={83}
              sx={{
                width: "100%",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#FE9800",
                },
              }}
            />
          </Div>
          <Div
            sx={{
              marginTop: "8px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Span>12.53 GB of 15 GB used</Span>
            <JumboButton
              variant="contained"
              sx={{
                marginTop: "10px",
                padding: "6px 8px 6px 8px",
                gap: "8px",
                borderRadius: " 4px ",
                backgroundColor: "#371B65",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "20px",
                letterSpacing: "0.05em",
                color: "#ffffff",
              }}
            >
              Get More Storage
            </JumboButton>
          </Div>
        </Div>
      </Div>
    </>
  );
};

JumboVerticalNavbar.defaultProps = {
  items: PropTypes.array,
  translate: false,
};

export default JumboVerticalNavbar;
