import React, { useState } from "react";

import FoldersList from "./components/FolderList/FolderList";
import DashboardLayout from "./../../../layouts/dashboard-layout/DashboardLayout";

function MiscDashboard() {
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleDocumentSelection = (selected) => {
    setSelectedUsers(selected);
  };
  return (
    <DashboardLayout selectedUsers={selectedUsers} isFolderView={true}>
      <FoldersList
        selectedUsers={selectedUsers}
        onDocumentSelect={handleDocumentSelection}
      />
    </DashboardLayout>
  );
}

export default MiscDashboard;
