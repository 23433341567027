import React from 'react';
import {Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
const PageHeader = ({title, subheader, showAddContactDialog, isAddButton , buttonText}) => {
    return (
        <Div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 4
            }}
        >
            <Div
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent:"space-between",
                mb: 4
            }}
        >    <Typography variant={"h2"}>{title}</Typography> 
        {isAddButton &&  <Button
               
                disableElevation
                variant={"contained"}
                startIcon={<PersonAddIcon/>}
                sx={{
                    mb: 2,
                    '& .MuiSvgIcon-root': {
                        fontSize: '1.5rem'
                    }
                }}
                onClick={showAddContactDialog}
            >
                 {buttonText || "Add Contact"}
            </Button>}
        
            </Div>
          
            <Typography variant={"body1"} mb={2} color={"text.secondary"}>{subheader}</Typography>
        </Div>
    );
};

export default PageHeader;