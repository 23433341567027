import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";

const DocumentSortCard = ({ Iconsrc, title, subtitle }) => {
  return (
    <Card
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "7px",
        padding: "0px",
        maxWidth: { lg: "130px", md: "120px", xs: "120px" },
        // minWidth: { lg: "95px", md: "95px", xs: "95px" },
        height: "45px",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0px",
          padding: {
            lg: "8px 8px 8px 8px",
            md: "8px 8px 8px 8px",
            xs: "5px 5px 5px 5px",
          },
        }}
      >
        <img
          src={Iconsrc}
          alt="icon"
          width={18}
          style={{ marginBottom: "10px" }}
        />
        <Box
          sx={{
            ml: 1,
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: { lg: "10px", md: "10px", xs: "8px" },
              fontWeight: "600",
              lineHeight: "18.07px",
              textAlign: "left",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "8px",
              fontWeight: "400",
              lineHeight: "18.07px",
              textAlign: "left",
            }}
          >
            {subtitle}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DocumentSortCard;
