

export const packageDetail = [
    {
        id: 1234,
    
        name: "Data Modelling",
       
       
        
    },
    {
        id: 1235,
    
        name: "Version control",
       
        
    },
    {
        id: 1236,
    
        name: "Export",
       
        
    },
    {
        id: 1237,
    
        name: "Collaboration",
       
        
    },
    {
        id: 1238,
    
        name: "Integration",
       
        
    },
    {
        id: 1239,
    
        name: "Lineage",
       
        
    },
    {
        id: 1240,
    
        name: "Legal",
       
        
    },
    {
        id: 1241,
    
        name: "Support",
       
        
    },
    {
        id: 1242,
    
        name: "Security",
       
        
    },
    {
        id: 1243,
    
        name: "Snowflake schema monitoring",
       
        
    },
    {
        id: 1244,
    
        name: "Data Governance",
       
        
    },
    
];



