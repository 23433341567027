import React from "react";
import { Typography, Box } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useTranslation } from "react-i18next";
import { ASSET_IMAGES } from "./../../../utils/constants/paths";
import { getAssetPath } from "./../../../utils/appHelpers";

const UserOrders = ({ iconSrc }) => {
  const { t } = useTranslation();
  return (
    <JumboCardQuick
      noWrapper
      sx={{
        height: "120px",
        borderRadius: "12px",
        textAlign: "center",
      }}
      title={
        <>
          <img
            src={iconSrc}
            // src={getAssetPath(`${ASSET_IMAGES}/wordicon.png`, "41x41")}
            alt="pin"
            width={41}
            style={{ position: "relative" }}
          />
          <img
            src={getAssetPath(`${ASSET_IMAGES}/pin-bold.png`, "24x24")}
            alt="pin"
            width={24}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          />
          <Typography
            sx={{
              color: " #000000",
              fontFamily: "Roboto",
              fontSize: { lg: "14px", md: "12px" },
              fontWeight: 500,
              lineHeight: "31px",
            }}
          >
            Daily task tracking
          </Typography>
        </>
      }
      subheader={
        <Typography variant={"h6"} color={"text.secondary"} mb={0}>
          100 MB
        </Typography>
      }
    ></JumboCardQuick>
  );
};

export default UserOrders;
