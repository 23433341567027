import React from "react";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import {
  Card,
  IconButton,
  Typography,
  ListItemIcon,
  ListItemText,
  TableCell,
  TableRow,
  Checkbox,
} from "@mui/material";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import { useNavigate } from "react-router-dom";
import { getAssetPath } from "../../../../../utils/appHelpers";
import { ASSET_IMAGES } from "../../../../../utils/constants/paths";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import JumboBookmark from "@jumbo/components/JumboBookmark";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Item = styled(Span)(({ theme }) => ({
  //   padding: theme.spacing(0, 1),
  //   display: "flex",
  //   flexDirection: "row",
  //   alignItems: "center",
}));

const FilesListItem = ({ user, isSelected, handleCheckboxChange }) => {
  const navigate = useNavigate();

  const showEditUser = () => {
    navigate(`/dashboards/users/edit-user/${user.id}`);
  };

  return (
    <Card sx={{ mb: 1 }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isSelected}
            onChange={() => handleCheckboxChange(user.id)}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{ width: { lg: "30%", md: "30%", xs: "60%" } }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Item>
              <img
                src={getAssetPath(`${ASSET_IMAGES}/wordicon.png`, "32x32")}
                alt="pin"
                width={32}
              />
            </Item>
            <Item
              sx={{
                flex: { xs: 1, md: "0 1 45%", lg: "0 1 50%" },
                ml: 1,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: { lg: "14px", md: "12px", xs: "10px" },
                  fontWeight: 500,
                  lineHeight: "31px",
                }}
              >
                {user.fullName}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: { lg: "12px", md: "10px", xs: "8px" },
                  fontWeight: 500,
                  lineHeight: "31px",
                  color: "#A5A5A5",
                }}
              >
                100 MB
              </Typography>
            </Item>
          </Stack>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "20%",
            display: { lg: "table-cell", md: "table-cell", xs: "none" },
          }}
        >
          <Typography
            sx={{
              fontSize: { lg: "12px", md: "10px", xs: "10px" },
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: " 0.025em",
              color: "#9CA3AF",
            }}
          >
            {user?.dateShared}
          </Typography>
        </TableCell>
        <TableCell
          align="Left"
          sx={{
            width: "20%",
            display: { lg: "table-cell", md: "table-cell", xs: "none" },
          }}
        >
          <Typography
            mb={0.5}
            sx={{
              fontSize: { lg: "12px", md: "10px", xs: "10px" },
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: " 0.025em",
              color: "#9CA3AF",
            }}
          >
            {user.sharedBy}
          </Typography>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: { lg: "30%", xs: "40%", md: "30%" },
          }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Item>
              <img
                src={getAssetPath(`${ASSET_IMAGES}/editIcon.png`, "24x24")}
                alt="pin"
                width={24}
              />
            </Item>
            <Item>
              {" "}
              <Typography
                mb={0.5}
                sx={{
                  ml: 1,
                  fontSize: { lg: "12px", md: "10px", xs: "10px" },
                  fontWeight: 500,
                  lineHeight: "16px",
                  letterSpacing: " 0.025em",
                  color: "#9CA3AF",
                }}
              >
                {user.activity}
              </Typography>
            </Item>
          </Stack>
        </TableCell>
      </TableRow>
    </Card>
  );
};

export default FilesListItem;
