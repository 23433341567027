
import {ASSET_AVATARS} from "../../../.././utils/constants/paths";
import {getAssetPath} from "../../../.././utils/appHelpers";

export const menuItems = [
    {
        icon:null,
        title: "View",
        slug: "all",
    },
    {
        icon:null,
        title: "Edit",
        slug: "edit",
    },
    {
        icon:null,
        title: "Delete",
        slug: "delete",
    }
];

export const roles = [
    {
      name:"Admin",
      description: "Administrator",
      createdAt:"23-03-2019"
    },
    {
        name:"Super Admin",
        description: "Administrator",
        createdAt:"23-03-2019"
      },
      {
        name:"Associates",
        description: "Associates",
        createdAt:"23-03-2019"
      },
      {
        name:"Customer",
        description: "Customer",
        createdAt:"23-03-2019"
      },
   
];

export const rolesName = [
    {
        name: 'USD',
        code: 'usd',
    },
    {
        name: 'INR',
        code: 'inr',
    },
    {
        name: 'EURO',
        code: 'euro',
    },
];

