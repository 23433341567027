import React,{useState} from 'react';
import {users} from "../../data";
import UserItem from "./UserItem";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import TablePagination from "@mui/material/TablePagination";
import PageHeader from "../../../../../layouts/shared/headers/PageHeader/PageHeader";
import {useNavigate} from "react-router-dom";
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';


const UsersList = () => {
    const navigate = useNavigate();
    const {showDialog, hideDialog} = useJumboDialog();
    const [userList, setUserList] = useState(users);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setTimeout(() => {
        //   refetch();
        }, 500);
      };
    
      const handleChangeRowsPerPage = (
        event
      ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setTimeout(() => {
        //   refetch();
        }, 500);
      };
   const handleAddUser =()=>{
    navigate(`/dashboards/users/add-user`)}

    const handleDeleteUser = (userId) => {
        showDialog({
            variant: 'confirm',
            title: 'Are you sure about deleting this contact?',
            content: "You won't be able to recover this contact later",
            onYes: () => {
                setUserList(prevUsers => prevUsers.filter(user => user.id !== userId));
                hideDialog(); // Close the dialog after deleting the user
            },
            onNo: hideDialog
        })   

    };
    return (
       
            <JumboContentLayout
              
                header={
                    <PageHeader
                        title={"Users"}
                        showAddContactDialog = {handleAddUser}
                        isAddButton    
                        buttonText={"Add User"}                    
                    />
                }
            >
               
               {
                userList.map((user, index) => (
                    <UserItem user={user} key={index} deleteUser={() => handleDeleteUser(user.id)}/>
                ))
            }

<TablePagination
              component="div"
              count={30 || 0}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[20, 30, 50]}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> 
             </JumboContentLayout>
       
    );
};
export default UsersList;



