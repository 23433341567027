
import {useNavigate} from "react-router-dom";
import { useQueryClient } from "react-query";
import {
  addRole,
  editRole,
  deleteRole 
} from "../../../../services/pages.services";
import { QueryKeys } from "../../../../utils/QueryKeys";


const useRoleApiActions = () => {

  const queryClient = useQueryClient();


  const tryAddRole= async (values) => {
    try {
      const response= await addRole(values); // login service
      if (response) {
        queryClient.invalidateQueries([QueryKeys.role]);
       
      }
      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      // snackbar?.show({
      //   title: msg,
      //   type: "error",
      // });
      return false;
    }
  };


  const tryEditRole= async (values , id) => {
    try {
      const response= await editRole(values , id); // login service
      if (response) {
          queryClient.invalidateQueries([QueryKeys.role]);
        // snackbar?.show({
        //   title: ErrorMessages.login.signSuccess,
        //   type: "success",
        // });
      }
      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      // snackbar?.show({
      //   title: msg,
      //   type: "error",
      // });
      return false;
    }
  };


  const tryDeleteRole = async (id) => {
    try {
      const response = await deleteRole(id);
      if (response) {
        queryClient.invalidateQueries([QueryKeys.role]);

        // snackbar?.show({
        //   title: response.message,
        //   type: "success",
        // });
      }
      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      // snackbar?.show({
      //   title: msg,
      //   type: "error",
      // });
      return false;
    }
  };

  return {
    tryDeleteRole,
    tryEditRole,
    tryAddRole,
 
  };
};

export {useRoleApiActions};

