import React from "react";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import {
  Card,
  IconButton,
  Typography,
  ListItemIcon,
  ListItemText,
  TableCell,
  TableRow,
  Checkbox,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import { useNavigate } from "react-router-dom";
import { getAssetPath } from "../../../../../utils/appHelpers";
import { ASSET_IMAGES } from "../../../../../utils/constants/paths";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import JumboBookmark from "@jumbo/components/JumboBookmark";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Item = styled(Span)(({ theme }) => ({}));

const FilesListItem = ({ user, isSelected, handleCheckboxChange }) => {
  const navigate = useNavigate();

  console.log("user--->", user);

  const showEditUser = () => {
    navigate(`/dashboards/users/edit-user/${user.id}`);
  };

  const handleGoToFile = () => {
    navigate(`/dashboards/file-details/${user.id}`);
  };

  return (
    <Card sx={{ mb: 1 }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isSelected}
            onChange={() => handleCheckboxChange(user.id)}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{ width: { lg: "40%", md: "40%", xs: "60%" } }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            onClick={handleGoToFile}
          >
            <Item>
              <img
                src={getAssetPath(`${ASSET_IMAGES}/wordicon.png`, "32x32")}
                alt="pin"
                width={32}
              />
            </Item>
            <Item
              sx={{
                flex: { xs: 1, md: "0 1 45%", lg: "0 1 50%" },
                ml: 1,
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: { lg: "14px", md: "12px", xs: "10px" },
                  fontWeight: 500,
                  lineHeight: "31px",
                }}
              >
                {user.docName}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: { lg: "12px", md: "10px", xs: "8px" },
                  fontWeight: 500,
                  lineHeight: "31px",
                  color: "#A5A5A5",
                }}
              >
                100 MB
              </Typography>
            </Item>
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "column", lg: "row" }}
              alignItems="center"
              sx={{ mt: { md: 1, lg: 0 } }} // Add margin-top for md to create space when in column
            >
              {/* <Item sx={{ mb: "12px" }}> */}
              <JumboBookmark
                value={user.isFavorite}
                sx={{ verticalAlign: "middle" }}
              />

              <MoreHorizIcon
                fontSize={"small"}
                sx={{ verticalAlign: "middle" }}
              />
              <img
                src={getAssetPath(`${ASSET_IMAGES}/pin-bold.png`, "14x14")}
                alt="pin"
                width={14}
                style={{
                  verticalAlign: "middle",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
              <img
                src={getAssetPath(`${ASSET_IMAGES}/shareIcon.png`, "14x14")}
                alt="pin"
                width={14}
                style={{ verticalAlign: "middle" }}
              />
            </Box>
          </Stack>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "15%",
            display: { lg: "table-cell", md: "table-cell", xs: "none" },
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: " 0.025em",
              color: "#9CA3AF",
            }}
          >
            {user?.modified}
          </Typography>
        </TableCell>
        <TableCell
          align="Left"
          sx={{
            width: "18%",
            display: { lg: "table-cell", md: "table-cell", xs: "none" },
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: " 0.025em",
              color: "#9CA3AF",
            }}
          >
            {user?.modifiedBy}
          </Typography>
        </TableCell>
        <TableCell
          align="Left"
          sx={{
            width: "12%",
            display: { lg: "table-cell", md: "table-cell", xs: "none" },
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: " 0.025em",
              color: "#9CA3AF",
            }}
          >
            {user?.itemCount} items
          </Typography>
        </TableCell>
        {/* <TableCell align="Left" style={{ width: "10%" }}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: " 0.025em",
              color: "#9CA3AF",
            }}
          >
            {user?.sharing}
          </Typography>
        </TableCell> */}
        <TableCell
          align="left"
          sx={{ width: { lg: "15%", md: "40%", xs: "40%" } }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Item>
              <img
                src={getAssetPath(`${ASSET_IMAGES}/editIcon.png`, "24x24")}
                alt="pin"
                width={24}
              />
            </Item>
            <Item>
              {" "}
              <Typography
                sx={{
                  ml: 1,
                  fontSize: { lg: "12px", md: "10px", xs: "8px" },
                  fontWeight: 500,
                  lineHeight: "16px",
                  letterSpacing: " 0.025em",
                  color: "#9CA3AF",
                }}
              >
                edited this|Apr 17
              </Typography>
            </Item>
          </Stack>
        </TableCell>
      </TableRow>
    </Card>
  );
};

export default FilesListItem;
