import React, { useState } from "react";
import { arrayMove, SortableContainer } from "react-sortable-hoc";
import contactsList from "./data";
import ContactCell from "./ContactCell";
import { Table, TableBody } from "@mui/material";
import JumboCard from "@jumbo/components/JumboCard";
import TableContainer from "@mui/material/TableContainer";

const Contacts = SortableContainer(({ contacts }) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <TableBody>
          {contacts.map((contact, index) => (
            <ContactCell key={index} index={index} contact={contact} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

const UploadList = () => {
  const [contacts, setContacts] = useState(contactsList);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setContacts(arrayMove(contacts, oldIndex, newIndex));
  };

  return (
    <JumboCard
      wrapperSx={{
        p: 0,
        backgroundColor: "background.paper",

        "&:last-child": {
          pb: 0,
        },
      }}
    >
      <Contacts
        contacts={contacts}
        onSortEnd={onSortEnd}
        useDragHandle={true}
      />
    </JumboCard>
  );
};

export default UploadList;
