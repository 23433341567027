
/**
 * @format
 */
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "@jumbo/constants/ErrorMessages";



const defaultValues= {

  name: "",
  description: "",
  companyId:""
};

const schema = Yup.object().shape({

  name: Yup.string().required(ErrorMessages.user.firstName),
  description: Yup.string().required("Please enter description")

});

const useRoleForm = (
    onSubmit,
    initialValues = defaultValues,
  ) => {
    return useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: schema,
      validateOnChange: true,
      validateOnBlur: true,
      validateOnMount: true,
      onSubmit,
    });
  };

  export default useRoleForm;
