import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import {
  login,
  signup,
  workspace,
  forgetPassword,
  resetPassword,
} from "../../../../services/auth.services";
import { useAuthActions } from "../../../../redux/user/auth";
import { useSnackbar } from "notistack";

const useAuthApiActions = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { setUserCredential, setUserInfo } = useAuthActions();

  const tryLogin = async (values) => {
    try {
      const response = await login(values);
      if (response) {
        enqueueSnackbar("User login successfully.", {
          variant: "success",
        });

        setUserCredential({
          token: response.token,
          isLoggedIn: true,
        });
        const decoded = jwtDecode(response.token);
        console.log("decoded--->", decoded);
        setUserInfo({
          userInfo: decoded,
          isLoggedIn: false,
          token: "",
        });
        navigate("/dashboards/misc");
      }
      return true;
    } catch (err) {
      const msg = err.message || "Something went wrong";
      enqueueSnackbar(msg, {
        variant: "error",
      });

      // navigate("/dashboards/misc");

      return false;
    }
  };

  const trySignup = async (values) => {
    try {
      const response = await signup(values); // login service
      console.log("status code", response.code);
      if (response.status === 201) {
        navigate("/user/login");
        enqueueSnackbar("User signup successfully.", {
          variant: "success",
        });
      }
      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      enqueueSnackbar(msg, {
        variant: "error",
      });
      return false;
    }
  };

  const tryWorkspace = async (values) => {
    try {
      const response = await workspace(values); // login service
      console.log("status code", response);
      if (response) {
        enqueueSnackbar("User signup successfully.", {
          variant: "success",
        });
        navigate("/packages");
      }
      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      enqueueSnackbar(msg, {
        variant: "error",
      });
      return false;
    }
  };

  const tryForgetPassword = async (values) => {
    try {
      const response = await forgetPassword(values);
      console.log("status code", response);
      if (response) {
        enqueueSnackbar("email send successfully.", {
          variant: "success",
        });
      }
      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      enqueueSnackbar(msg, {
        variant: "error",
      });
      return false;
    }
  };

  const tryResetPassword = async (values) => {
    try {
      const response = await resetPassword(values);
      if (response) {
        enqueueSnackbar("user info updated successfully.", {
          variant: "success",
        });
        navigate("/");
      }

      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      enqueueSnackbar(msg, {
        variant: "error",
      });
      return false;
    }
  };

  return {
    tryLogin,
    trySignup,
    tryWorkspace,
    tryForgetPassword,
    tryResetPassword,
  };
};

export { useAuthApiActions };
