import React, { useState, useEffect } from "react";
import Div from "@jumbo/shared/Div";
import LoadingButton from "@mui/lab/LoadingButton";
import { Typography } from "@mui/material";
import useSignupForm from "./../hooks/useSignupForm";
import { useAuthApiActions } from "../query/useAuthApiActions";
import OutlinedSecondaryTextField from "./../../../../../@jumbo/components/Textfield/index";
import { useNavigate } from "react-router-dom";
import { useSignupFormActions } from "../../../../redux/userData/userData.slice";

const defaultValues = {
  mobile: "",
  firstName: "",
  LastName: "",
  email: "",
};

const NewSignupForm = () => {
  const navigate = useNavigate();

  const { trySignup } = useAuthApiActions();
  const { setFormData } = useSignupFormActions();

  const onSubmit = async (values) => {
    setFormData(values);

    navigate("/user/password");
  };

  const formik = useSignupForm(onSubmit, defaultValues);

  const { handleBlur, handleChange, handleSubmit, values, errors, touched } =
    formik;

  return (
    <>
      <Div sx={{ mt: "20px" }}>
        <Typography
          fontSize={{ lg: "30px", md: "20px" }}
          color="#371B65"
          fontWeight={500}
          mb={3}
          lineHeight={"47px"}
          letterSpacing={"5%"}
          fontFamily={"Roboto"}
        >
          Create Account
        </Typography>
      </Div>
      <Div>
        <Div sx={{ mt: "20px", width: { lg: "433px", md: "80%", xs: "100%" } }}>
          <OutlinedSecondaryTextField
            focused
            error={!!touched.firstName && !!errors.firstName}
            helperText={(touched.firstName && errors && errors.firstName) || ""}
            label="First Name"
            placeholder={"eg. Ankit"}
            style={{
              width: "100%",
            }}
            value={values.firstName}
            onBlur={handleBlur("firstName")}
            onChange={handleChange("firstName")}
          />
        </Div>
        <Div sx={{ mt: "20px", width: { lg: "433px", md: "80%", xs: "100%" } }}>
          <OutlinedSecondaryTextField
            focused
            error={!!touched.lastName && !!errors.lastName}
            helperText={(touched.lastName && errors && errors.lastName) || ""}
            label="LastName"
            variant="outlined"
            placeholder={"eg. Jain"}
            style={{
              width: "100%",
            }}
            value={values.lastName}
            onBlur={handleBlur("lastName")}
            onChange={handleChange("lastName")}
          />
        </Div>
        <Div sx={{ mt: "20px", width: { lg: "433px", md: "80%", xs: "100%" } }}>
          <OutlinedSecondaryTextField
            focused
            fullWidth
            name="email"
            label="Email Address"
            placeholder={"eg. ankit@gmail.com"}
            style={{
              width: "100%",
            }}
            error={!!touched.email && !!errors.email}
            helperText={(touched.email && errors && errors.email) || ""}
            value={values.email}
            onBlur={handleBlur("email")}
            onChange={handleChange("email")}
          />
        </Div>
        <Div sx={{ mt: "20px", width: { lg: "433px", md: "80%", xs: "100%" } }}>
          <OutlinedSecondaryTextField
            focused
            fullWidth
            name="mobile"
            label="Mobile Number"
            style={{
              width: "100%",
            }}
            placeholder={"eg. 9948439833"}
            error={!!touched.mobile && !!errors.mobile}
            helperText={(touched.mobile && errors && errors.mobile) || ""}
            value={values.mobile}
            onBlur={handleBlur("mobile")}
            onChange={handleChange("mobile")}
          />
        </Div>
      </Div>

      <LoadingButton
        fullWidth
        variant="contained"
        size="large"
        sx={{
          mt: "23px",
          padding: "5.43px, 7.24px, 5.43px, 7.24px",
          width: { lg: "433px", md: "80%", xs: "100%" },
          height: "50px",
          backgroundColor: "#371B65",
          fontFamily: "Roboto",
          fontSize: { lg: "14px", md: "12px", xs: "10px" },
          fontWeight: 500,
          lineHeight: "20px",
          letterSpacing: "0.05em",
          textAlign: "left",
          textTransform: "none",
        }}
        onClick={() => handleSubmit()}
      >
        Next
      </LoadingButton>
    </>
  );
};

export default NewSignupForm;
