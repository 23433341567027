const { REACT_APP_BASE_URL } = process.env;

const endPoints = {
  login: "login",
  signup: "signup",
  role: "role",
  user: "user",
  industry: "industries",
  industryDept: "industry-department",
  empStrength: "employee-strength",
  workspace: "workspace-auth",
  forgetPassword: "forgot-password",
  resetPassword: "reset-password",
};

const API_URLS = {
  LOGIN: `${REACT_APP_BASE_URL}/${endPoints.login}`,
  SIGNUP: `${REACT_APP_BASE_URL}/${endPoints.signup}`,
  ROLE: `${REACT_APP_BASE_URL}/${endPoints.role}`,
  ADD_USER: `${REACT_APP_BASE_URL}/${endPoints.user}`,
  UPDATE_USER_BY_ID: `${REACT_APP_BASE_URL}/${endPoints.user}`,
  INDUSTRY: `${REACT_APP_BASE_URL}/${endPoints.industry}`,
  INDUSTRYDEPT: `${REACT_APP_BASE_URL}/${endPoints.industryDept}`,
  EMP_STRENGTH: `${REACT_APP_BASE_URL}/${endPoints.empStrength}`,
  WORKSPACE: `${REACT_APP_BASE_URL}/${endPoints.workspace}`,
  FORGET_PASSWORD: `${REACT_APP_BASE_URL}/${endPoints.forgetPassword}`,
  RESET_PASSWORD: `${REACT_APP_BASE_URL}/${endPoints.resetPassword}`,
};

export default API_URLS;
