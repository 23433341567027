import React from "react";
import MiscDashboard from "../pages/dashboards/misc/MiscDashboard";
import MyFiles from "../pages/dashboards/my-files/MyFiles";
import GridDashboard from "../pages/dashboards/dashboard-gridview/DashboardGridView";
import Page from "@jumbo/shared/Page";
import Packages from "../pages/dashboards/packages/Packages";
import Settings from "../pages/dashboards/settings/Settings";
import UsersListing from "../pages/dashboards/users/UserListing";
import Company from "../pages/dashboards/company/Company";
import NewDocument from "../pages/dashboards/upload-document/index";
import SharedFiles from "../pages/dashboards/shared-files/SharedFiles";
import FavoriteFiles from "../pages/dashboards/favorites-files/FavoriteFiles";
import TrashFiles from "../pages/dashboards/trash-files/TrashFiles";
import FileDetail from "../pages/dashboards/file-detail/FileDetail";

const AddUserForm = React.lazy(() =>
  import("../pages/dashboards/users/component/UserForm/index")
);
const AddCompanyForm = React.lazy(() =>
  import("../pages/dashboards/company/component/CompanyForm/index")
);
const Workspace = React.lazy(() =>
  import("../pages/dashboards/workspace/index")
);

const dashboardRoutes = [
  // {
  //   path: "/dashboards/misc",
  //   element: <Page component={DashboardMisc} />,
  // },

  {
    path: "/dashboards/packages",
    element: <Page component={Packages} />,
  },
  {
    path: "/dashboards/settings",
    element: <Page component={Settings} />,
  },
  {
    path: ["/dashboards/users", "/dashboards/users/:category"],
    element: <Page component={UsersListing} />,
  },

  {
    path: "dashboards/users/add-user",
    element: <AddUserForm />,
  },

  {
    path: "dashboards/users/edit-user/:id",
    element: <AddUserForm />,
  },

  {
    path: [
      "/dashboards/company/:category",
      "/dashboards/company/:category/:id",
    ],
    element: <Page component={Company} />,
  },

  {
    path: "dashboards/company/add-company",
    element: <AddCompanyForm />,
  },

  {
    path: "/dashboards/misc",
    element: <Page component={MiscDashboard} />,
  },

  {
    path: "/dashboards/my-files",
    element: <Page component={MyFiles} />,
  },

  {
    path: "/dashboards/file-details/:id",
    element: <Page component={FileDetail} />,
  },
  {
    path: "/dashboards/shared-files",
    element: <Page component={SharedFiles} />,
  },
  {
    path: "/dashboards/favorite-files",
    element: <Page component={FavoriteFiles} />,
  },

  {
    path: "/dashboards/trash-files",
    element: <Page component={TrashFiles} />,
  },

  {
    path: "/dashboards/misc/grid-view",
    element: <Page component={GridDashboard} />,
  },

  {
    path: [
      "/dashboards/workspace",
      "/dashboards/workspace/:category",
      "/dashboards/workspace/:category/:id",
    ],
    element: <Page component={Workspace} />,
  },

  {
    path: ["/dashboards/upload-document", "/dashboards/upload-document/:id"],
    element: <Page component={NewDocument} />,
  },
];

export default dashboardRoutes;
