import React from "react";

import NewSignupForm from "./components/SignupForm";
import AuthLayout from "app/layouts/auth-layout/AuthLayout";

const Signup2 = () => {
  return (
    <AuthLayout
      title={"Basic Information"}
      description={"Enter your name, email address and mobile number"}
    >
      <NewSignupForm />
    </AuthLayout>
  );
};

export default Signup2;
