import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
} from "@mui/material";
import List from "@mui/material/List";
import LoaderWithBulletList from "./CustomLoader"; // Import your custom loader component here
import TranslateIcon from "@mui/icons-material/Translate";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import HotelIcon from "@mui/icons-material/Hotel";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Div from "./../../../../@jumbo/shared/Div/Div";

const FeaturesList = ({ isLoading, isMiddle }) => {
  return (
    <>
      {isLoading ? (
        <LoaderWithBulletList isBullet={true} />
      ) : (
        <>
          <List disablePadding>
            <ListItem>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "21.09px",
                  textAlign: "left",
                }}
              >
                Easy Translation
              </Typography>
            </ListItem>
            <ListItem>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "21.09px",
                  textAlign: "left",
                }}
              >
                250 GB storage
              </Typography>
            </ListItem>
            <ListItem>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "21.09px",
                  textAlign: "left",
                }}
              >
                250 GB storage
              </Typography>
            </ListItem>
            <ListItem>
              <Typography
                primary="250 GB storage"
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "21.09px",
                  textAlign: "left",
                }}
              >
                250 GB storage
              </Typography>
            </ListItem>
            <ListItem>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "21.09px",
                  textAlign: "left",
                }}
              >
                250 GB storage
              </Typography>
            </ListItem>
          </List>
          <Div sx={{ mt: "10px" }}>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "24.42px",
                textAlign: "left",
                color: "#4B5563",
              }}
            >
              Billed monthly after trial period of 2 weeks
            </Typography>
          </Div>
          <Button
            variant="outlined"
            sx={{
              mt: isMiddle ? "15px" : "19px",
              width: " 337px",
              height: "40px",
              padding: " 5.43px 7.24px 5.43px 7.24px",
              gap: "7.24px",
              borderRadius: "3.62px 0px 0px 0px",
              border: "1px 0px 0px 0px",
              opacity: "0px",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "18.11px",
              letterSpacing: "0.05em",
              textAlign: "left",
            }}
          >
            Try free for 60 dyas
          </Button>
        </>
      )}
    </>
  );
};

export default FeaturesList;
